import React from "react";
import { HTMLAttributes } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.scss";
import { App } from "./pages";
import {
  defineCustomElements,
  applyPolyfills,
  JSX as LocalJSX,
} from "@avaldigitallabs/focc-pid-lib-web-components/dist/loader";
import store from "./store";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { environment } from "./config/environment";
import { WebSocketProvider } from "./providers/websocketProvider.jsx";

ReactDOM.render(
  <Provider store={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey={environment.siteKeyRecaptchaV3}
      language="es-COL"
    >
      <WebSocketProvider>
        <App />
      </WebSocketProvider>
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById("root"),
);

/**
 * Configuration web components with stencil
 */
type StencilToReact<T> = {
  [P in keyof T]?: T[P] &
    Omit<HTMLAttributes<Element>, "className"> & {
      class?: string;
    };
};

declare global {
  export namespace JSX {
    interface IntrinsicElements
      extends StencilToReact<LocalJSX.IntrinsicElements> {
      pid?: string;
    }
  }
}

applyPolyfills().then(() => {
  defineCustomElements(window);
});
