import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorModal, HeaderContent } from "../../components";
import useTealium from "../../components/tealium/tealium";
import {
  ConstantPid,
  EventCodeEnum,
  StatusCodeEnum,
  WebsocketActionEnum,
} from "../../enums/constantPid";
import { IClient } from "../../interfaces/client.interface";
import { Field } from "../../models/Field";
import { investmentService } from "../../services";
import { updateClient } from "../../store/actions";
import { convertNumber } from "../../utils";
import { eventValid, updState, validObject } from "../../utils/validation";
import { AnalyticsEvent } from "../../classes/createEvent";
import { useWebSocket } from "../../providers/websocketProvider.jsx";

function Finance(props: any) {
  const { parameters, client, updClient, dataStore } = props;
  const navigate: any = useNavigate();

  const { socket } = useWebSocket();
  useTealium({
    type: "view",
    structure: {
      pagePath: window.location.pathname,
    },
  });
  const [jobs] = React.useState(parameters.profesion);
  const [values, setValues]: any = React.useState({
    occupation: new Field(),
    monthly_expenses: new Field(),
    actives: new Field(),
    pasives: new Field(),
    economic_activity: new Field(),
  });

  const [identityGlobal, setIdentityGlobal] = React.useState({
    canContinue: false,
    showLoading: false,
  });

  const [economicActivity] = React.useState(parameters.actividadEconomica);
  const [isVisibleActivity, setIsVisibleActivity] = React.useState(false);

  const [showErrors, setShowErrors]: any = React.useState({
    codes: [],
    show: false,
  });

  useEffect(() => {
    socket.getMessages$().subscribe((response: any) => {
      if (
        response.pid_response.action === WebsocketActionEnum.CREAR_INVERSION
      ) {
        if (response.pid_response.code == StatusCodeEnum.CODE_214) {
          updClient({
            ...client,
            investment: response.pid_response.data?.number,
          });
          handleGlobal(false, "showLoading");
          navigate("/confirmacion");
        } else {
          const details = [
            {
              key: "uuid",
              value: client.uuid,
            },
            {
              key: "Code",
              value: StatusCodeEnum.CODE_500,
            },
            {
              key: "Id",
              value: client.infoClient.id,
            },
            {
              key: "FullName",
              value: `${client.infoClient.names} ${client.infoClient.surnames}`,
            },
            {
              key: "Phone",
              value: client.infoClient.phone,
            },
            {
              key: "Email",
              value: client.infoClient.email,
            },
            {
              key: "Path",
              value: window.location.pathname,
            },
          ];
          const analyticsEventFail = getAnalyticsEvent(
            EventCodeEnum.CODE_61,
            details,
          );
          analyticsEventFail
            .sendEvent()
            .then()
            .catch((err) => {
              console.log("err", err);
            });

          setShowErrors({
            codes: [StatusCodeEnum.CODE_599],
            show: true,
          });
          handleGlobal(false, "showLoading");
        }
      }
    });
  }, []);

  useEffect(() => {
    const input: HTMLFoccAutocompleteElement =
      document.querySelector("#occupation")!;

    input.data = jobs;
    input?.addEventListener("selected", handleChange);

    const economicField: HTMLFoccAutocompleteElement =
      document.querySelector("#economic_activity")!;

    economicField.data = economicActivity;
    economicField?.addEventListener("selected", handleChange);

    const inputs = document.querySelectorAll("focc-field");
    inputs.forEach((input: any) => {
      input?.addEventListener("valid", validEvent);
    });
  }, []);

  const validEvent = (e: any) => {
    if (eventValid(e, values, setValues)) {
      handleGlobal(validObject(values), "canContinue");
    }
  };

  const handleGlobal = (value: any, name: string) => {
    updState(value, name, identityGlobal, setIdentityGlobal);
  };

  const addClientRdx = () => {
    const person: IClient = {
      ...client,
      sendAnality: {
        ...client.sendAnality,
        event: ["InfoFinance"],
      },
      infoFinance: {
        ocupation: values["occupation"].value,
        ocupation_name: values["occupation"].description,
        monthly_expenses: convertNumber(
          values["monthly_expenses"].value,
        ).toString(),
        actives: convertNumber(values["actives"].value).toString(),
        pasives: convertNumber(values["pasives"].value).toString(),
        ciiu: getCiuu().ciiu,
        ciiu_name: getCiuu().ciiu_name,
      },
    };
    handleGlobal(true, "showLoading");
    updClient(person);

    const details = [
      {
        key: "Monthly_expenses",
        value: convertNumber(values["monthly_expenses"].value).toString(),
      },
      {
        key: "Ocupation",
        value: values["occupation"].value,
      },
      {
        key: "Pasives",
        value: convertNumber(values["pasives"].value).toString(),
      },
      {
        key: "Actives",
        value: convertNumber(values["actives"].value).toString(),
      },
      {
        key: "uuid",
        value: client.uuid,
      },
    ];

    const analyticsEvent = getAnalyticsEvent(EventCodeEnum.CODE_48, details);
    analyticsEvent
      .sendEvent()
      .then()
      .catch((err) => {
        handleGlobal(false, "showLoading");
      });

    createInvestment();
  };

  const getAnalyticsEvent = (code: EventCodeEnum, details: any[]) => {
    return new AnalyticsEvent(code, dataStore, details);
  };

  const createInvestment = () => {
    const infoFinance = values;

    const investment: any = {
      client: {
        birth_city_name: client.infoBirth.birth_city_name ?? "",
        birth_city: client.infoBirth.birth_city ?? "",
        current_city_name: client.infoCurrent.current_city_name ?? "",
        current_city: client.infoCurrent.current_city ?? "",
        nationality_name: client.infoBirth.nacionality_name ?? "",
        nationality: client.infoBirth.nacionality ?? "",
        provider: "FIDUOCCIDENTE",
        type_id: "CC",
        id: `${client.infoClient.id}`,
        birth_date: client.infoBirth.birth_date ?? "",
        birth_country: client.infoBirth.birth_country ?? "",
        birth_country_name: client.infoBirth.birth_country_name ?? "",
        expedition_country: client.infoBirth.expedition_country ?? "",
        expedition_country_name: client.infoBirth.expedition_country_name ?? "",
        current_department: client.infoCurrent.current_department ?? "",
        current_address: client.infoCurrent.current_address ?? "",
        current_address_complement:
          client.infoCurrent.current_address_complement ?? "",
        email: client.infoClient.email ?? "",
        occupation: infoFinance.occupation.id ?? "",
        ciiu: getCiuu().ciiu ?? "",
        ciiu_name: getCiuu().ciiu_name ?? "",
        monthly_expenses:
          convertNumber(infoFinance.monthly_expenses.value).toString() ?? "",
        monthly_contribution:
          convertNumber(infoFinance.monthly_expenses.value).toString() ?? "",
        monthly_withdrawal:
          convertNumber(infoFinance.monthly_expenses.value).toString() ?? "",
        actives: convertNumber(infoFinance.actives.value).toString() ?? "",
        pasives: convertNumber(infoFinance.pasives.value).toString() ?? "",
        product_id: client.product.codigosifi ?? "",
        otp_value: client.otpValue ?? "",
        office_id: "2022",
        region: "6",
        advisor_id: ConstantPid.ADVISORID ?? "",
        employment_charge_name: "Cliente",

        employment_city_name: "",
        employment_charge: "035",
        employment_address: "",
        employment_address_complement: "",
        employment_phone_ind: "",
        employment_phone: "",
        employment_department: "",
        employment_city: "",
        expedition_date: client.infoClient.expedition ?? "",
        advisor_name: "",
        advisor_email: "",
        profile_selected: "",
        profile_recommended: "",
        profile_accepted: "",
        source_ip_address: parameters.source_ip ?? "",
      },
      analytics: {
        userAgent: client.sendAnality.userAgent,
        clientIdType: "CC",
        clientId: `${client.infoClient.id}`,
        channel: "PID App Auto",
        sessionId: client.uuid,
        advisorId: ConstantPid.ADVISORID,
        transactionId:
          client.infoClient.id + "-" + ConstantPid.ADVISORID + "-" + Date.now(),
      },
      info: [],
      controlData: {
        UUIDSession: client.uuid,
        isAuto: true,
        action: WebsocketActionEnum.CREAR_INVERSION,
      },
      controlSQS: {
        sendMessage: true,
        sqsReferenceName: "sqs-auto-crear-inversion",
      },
    };

    Object.keys(client.legal).forEach((property) => {
      investment.info.push({ key: property, value: client.legal[property] });
    });
    investmentService.createInvestment(investment).catch((e: Error) => {
      console.error("error create investment :>> ", e);
      handleGlobal(false, "showLoading");
    });
  };

  const getCiuu = () => {
    return {
      ciiu:
        values.occupation.value === "N"
          ? values.economic_activity.id
          : values.occupation.value,
      ciiu_name:
        values.occupation.value === "N"
          ? values.economic_activity.description
          : `${values.occupation.value} - ${values.occupation.description}`,
    };
  };

  const handleChange = (evt: any) => {
    const { target, detail, type } = evt;
    const { name, value } = target;
    if (type === "selected") {
      const { valid } = detail;
      const old_occupation = values[name];
      old_occupation.value = valid ? detail.value?.ciiu : null;
      old_occupation.valid = valid;
      old_occupation.description = valid ? detail.value?.description : null;
      old_occupation.id = valid ? detail.value?.value : null;
      old_occupation.isNotTarget = valid ? detail.value?.isNotTarget : null;
      setValues({
        ...values,
        [name]: old_occupation,
      });
      handleGlobal(validObject(values), "canContinue");
    } else {
      const old = values[name];
      old.value = value;
      updState(old, name, values, setValues);
    }

    if (name === "occupation") {
      const occupationValue = values[name].value;

      values["economic_activity"] = new Field();
      const activity = values["economic_activity"];

      const economicField: HTMLFoccAutocompleteElement =
        document.querySelector("#economic_activity")!;
      economicField?.clear();

      if (occupationValue !== null && occupationValue === "N") {
        setIsVisibleActivity(true);

        activity.value = null;
        activity.valid = false;

        handleGlobal(false, "canContinue");
      } else {
        setIsVisibleActivity(false);

        activity.value = null;
        activity.valid = true;
      }
    }

    if (name === "economic_activity") {
      if (values[name].isNotTarget && values[name].isNotTarget == "S") {
        setShowErrors({
          codes: [StatusCodeEnum.CODE_317],
          show: true,
        });
      }
    }
  };

  return (
    <div>
      {
        <HeaderContent
          title={"Cuéntanos de tus finanzas"}
          subtitle={"Tu información está segura"}
        />
      }
      <div className="form-container">
        <focc-field
          type="money"
          id="monthly_expenses"
          name="monthly_expenses"
          onInput={handleChange}
          onValid={validEvent}
          placeholder="Ej. $200.000"
          required
        >
          Gastos mensuales
        </focc-field>
        <focc-field
          type="money"
          id="actives"
          name="actives"
          onInput={handleChange}
          onValid={validEvent}
          placeholder="Ej. $700.000.000"
          required
        >
          Total de bienes
        </focc-field>
        <focc-field
          type="money"
          id="pasives"
          name="pasives"
          onInput={handleChange}
          onValid={validEvent}
          placeholder="Ej. $3000.000"
          required
        >
          Total de deudas
        </focc-field>

        <focc-autocomplete
          required
          id="occupation"
          name="occupation"
          data={jobs}
          onSelected={handleChange}
          find="description"
          placeholder="Ej. Empleado"
        >
          Ocupación
        </focc-autocomplete>

        <div
          className={
            isVisibleActivity
              ? "div-nacionality show-animation-container"
              : "div-nacionality"
          }
        >
          <focc-autocomplete
            required
            id="economic_activity"
            name="economic_activity"
            onSelected={handleChange}
            data={economicActivity}
            find="description"
            placeholder="Actividad económica"
          >
            Actividad económica
          </focc-autocomplete>
        </div>
      </div>
      <div className="center-button">
        <focc-button
          id="btnLoading"
          onClick={addClientRdx}
          loading={identityGlobal.showLoading ? "on" : "off"}
          disabled={!identityGlobal.canContinue}
        >
          Continuar
        </focc-button>
      </div>
      <ErrorModal
        errorCode={showErrors.codes[0]}
        isVisible={showErrors.show}
      ></ErrorModal>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    client: state.vinculation.client,
    parameters: state.configuration.parameters,
    dataStore: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updClient: (data: any) => dispatch(updateClient(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Finance);
