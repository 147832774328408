/* eslint-disable */
let environmentPath: string = "dev";
let environmentSupport: Array<string> = ["dev", "stg", "pro", "local"];
if (
  process.env.REACT_APP_ENV !== null &&
  process.env.REACT_APP_ENV !== undefined &&
  environmentSupport.indexOf(process.env.REACT_APP_ENV) !== -1
) {
  environmentPath = process.env.REACT_APP_ENV;
}

export const environment = require(`../environments/${environmentPath}.json`);
/* eslint-disable */
