import React, { useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { environment } from "../config/environment";
import { WebSocketService } from "../services/websocket/websocket-service";

const webSocketContext = React.createContext();

export function WebSocketProvider({ children }) {
  const [socket, setSocket] = useState(null);
  const [socketId, setSocketId] = useState();

  const createNewConnection = () => {
    const newSocketId = uuidv4();
    setSocketId(newSocketId);

    if (socket) {
      socket.close();
    }

    const protocols = ["v2.pid.fiduoccidente.com", `${environment.key_ws}`];

    const newSocket = new WebSocketService(environment.ws, [
      ...protocols,
      newSocketId,
    ]);
    setSocket(newSocket);

    return newSocket;
  };

  const value = {
    socket,
    createNewConnection,
    socketId,
  };

  return (
    <webSocketContext.Provider value={value}>
      {children}
    </webSocketContext.Provider>
  );
}

export const useWebSocket = () => {
  return useContext(webSocketContext);
};
