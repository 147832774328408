import React from "react";
import logoFidu from "../../assets/img/logo-fidu-white.svg";
import logoAval from "../../assets/img/logo-aval-footer.svg";
import logoVigilado from "../../assets/img/logo-vigilado-horizontal.svg";
import { Col, Layout, Row } from "antd";
import { Outlet } from "react-router";
import TimeOutModal from "../../components/timeOut-modal/timeOut-modal";

const { Header, Footer, Content } = Layout;

function Home() {
  return (
    <Layout>
      <Header className="header">
        <img src={logoFidu} alt="logo-fidu" width="167px" height="34px" />
      </Header>

      <Content>
        <Row>
          <Col
            className="container-principal"
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 12, offset: 6 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 12, offset: 6 }}
            xxl={{ span: 8, offset: 8 }}
          >
            <TimeOutModal></TimeOutModal>
            <Outlet />
          </Col>
        </Row>
      </Content>

      <Footer className="footer">
        <img src={logoAval} alt="logo-aval" />
        <br />
        <img src={logoVigilado} alt="logo-super" />
      </Footer>
    </Layout>
  );
}

export default Home;
