import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetStore } from "../../store/actions";
import IMAGES from "../../assets/img";
import { environment } from "../../config/environment";
import useTealium from "../../components/tealium/tealium";

function Confirmation(props: any) {
  const { client, resetRedux } = props;
  const [tealium] = useTealium({
    type: "view",
    structure: {
      pagePath: window.location.pathname,
    },
  });
  const navigate: any = useNavigate();

  const newInvestment = () => {
    resetRedux();
    navigate("/");
  };

  return (
    <section className="flex-simple-center">
      <img
        className="imagen m-auto"
        src={IMAGES["credito-aprobado"]}
        width="75"
        alt="..."
      />
      <h1 className="title mb-0 mt-10">¡Felicidades!</h1>
      <h1 className="title mb-0">
        {client.infoClient.names} {client.infoClient.surnames}
      </h1>

      <h1 className="subtitle text-center my-28">
        Te has vinculado exitosamente al fondo de inversión:
      </h1>

      <figure>
        <img
          alt="imagen fondo"
          src={`${environment.cdn}/FondosEmail/img-fondo-${client.product.codigosifi}.svg`}
          width="102"
          height="72"
        />
      </figure>

      <h2 className="subtitle">No. {client.investment}</h2>

      <h1 className="subtitle">
        En los siguientes canales puedes realizar tu primer aporte para seguir
        construyendo metas juntos
      </h1>

      <article className="form-container">
        {client.product.codigosifi !== "119-0" && (
          <>
            <figure className="aporte-list">
              <img alt="" src={IMAGES["portal-banco"]} width="32" />
              <div>
                <h4>Portal Banco</h4>
                <a
                  href="https://www.bancodeoccidente.com.co/portaltransaccional/#/login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visítalo aquí
                </a>
              </div>
            </figure>
            <figure className="aporte-list">
              <img alt="" src={IMAGES["fidu-inversion"]} width="32" />
              <div>
                <h4>Fiduinversión</h4>
                <a
                  href="https://fiduinversion.fiduoccidente.com.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visítalo aquí
                </a>
              </div>
            </figure>
          </>
        )}

        {/* <figure className="aporte-list">
          <img alt="" src={IMAGES["pse"]} width="32" />
          <div>
            <h4>PSE</h4>
            <a>Visítalo aquí</a>
          </div>
        </figure> */}
        <figure className="aporte-list">
          <img alt="" src={IMAGES["oficina"]} width="32" />
          <div>
            <h4>Oficinas</h4>
            <a>Visítalo aquí</a>
          </div>
        </figure>
      </article>
      <p className="text-left f-size-14">
        A tu correo te enviamos un resumen de la vinculación para que puedas
        consultarla en cualquier momento.
      </p>
      <div className="container-flex">
        <focc-button id="btnLoading" onClick={newInvestment}>
          Crear una nueva inversión
        </focc-button>
        <a
          className="button"
          href={environment.encuesta_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Cuéntanos tu experiencia{" "}
        </a>
      </div>
    </section>
  );
}

const mapStateToProps = (state: any) => {
  return {
    client: state.vinculation.client,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetRedux: () => dispatch(resetStore()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
