import React from "react";
import { Col, Layout, Row } from "antd";
import { environment } from "../../config/environment";
import logoFidu from "../../assets/img/logo-fidu-white.svg";
import logoAval from "../../assets/img/logo-aval-footer.svg";
import logoVigilado from "../../assets/img/logo-vigilado-horizontal.svg";
import mantenimiento from "../../assets/img/mantenimiento.svg";

const { Header, Footer, Content } = Layout;

function Maintenance() {
  return (
    <Layout className="content-maintenance">
      <Header className="header">
        <img src={logoFidu} alt="logo-fidu" width="167px" height="34px" />
      </Header>

      <Content className="content-maintenance">
        <Row>
          <Col
            className=""
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 12, offset: 6 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 12, offset: 6 }}
            xxl={{ span: 8, offset: 8 }}
          >
            <div className="maintenance-info">
              <div className="">
                <img src={mantenimiento} alt="mantenimiento" />
              </div>
              <div className="maintenance-style-title">
                <h1 className="mmaintenance-info-head">
                  ¡Lo sentimos! Estamos realizando unos ajustes en la
                  herramienta
                </h1>
              </div>

              <div className="info-extra">
                Por favor inténtalo más tarde o comunícate con un asesor para
                que te ayude a abrir tu fondo de inversión
              </div>
              <br></br>

              <div className="maintenance-linea-servcio">
                Línea de servicio al cliente Bogotá (601) 742 74 45 ó resto del
                país 01 8000 189 818
              </div>
              <br></br>

              <div className="mantenimiento-button">
                <focc-button
                  id="redirect_fidu"
                  className="button-primary"
                  onClick={() => window.open(environment.fiduoccidente_url)}
                >
                  Ir a FiduOccidente
                </focc-button>
              </div>
            </div>
          </Col>
        </Row>
      </Content>

      <Footer className="footer">
        <img src={logoAval} alt="logo-aval" />
        <br />
        <img src={logoVigilado} alt="logo-super" />
      </Footer>
    </Layout>
  );
}

export default Maintenance;
