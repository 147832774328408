import { combineReducers } from "redux";
import clientReducer from "./client-reducer";
import configurationReducer from "./configuration-reducer";
import productReducer from "./product-reducer";

export default combineReducers({
  configuration: configurationReducer,
  vinculation: clientReducer,
  product: productReducer,
});
