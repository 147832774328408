import axios from "axios";
import { environment } from "../../config/environment";

const getMaintenance = () => {
  const http = axios.create({
    baseURL: environment.url,
    headers: {
      "Content-type": "application/json",
      "x-api-key": environment.maintenanceToken || "",
    },
  });
  return http.get("/estadoFlujosAuto");
};

export default {
  getMaintenance,
};
