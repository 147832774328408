import React from "react";
import { Row, Col } from "antd";
import IMAGES from "../../assets/img";

// Defines more precise types for the 'error' parameter in typeOptions
type AlertType = "error" | "warning";

// Uses an object to map the types to the alert classes
const typeOptions: Record<AlertType, string> = {
  error: "alert-error",
  warning: "alert-warning",
};

interface AlertProps {
  type: AlertType;
  text: string;
}

function Alert({ type, text }: AlertProps) {
  return (
    <Row>
      <Col
        className=""
        xs={{ span: 22, offset: 1 }}
        sm={{ span: 22, offset: 1 }}
        md={{ span: 22, offset: 1 }}
        lg={{ span: 22, offset: 1 }}
        xl={{ span: 22, offset: 1 }}
        xxl={{ span: 22, offset: 1 }}
      >
        <div className="alert-container">
          <div className="alert-icon">
            <img alt="" src={IMAGES["errorAlert"]} width="19" />
          </div>
          <div className="alert-text">{text}</div>
        </div>
      </Col>
    </Row>
  );
}

export { typeOptions, Alert };
