import { ConstantPid, EventCodeEnum } from "../enums/constantPid";
import { analyticService } from "../services";
import eventMnemonic from "../assets/js/eventMnemonic.json";
import { convertNumberToStringWithoutSymbols } from "../utils/conversions";

export class AnalyticsEvent {
  private _eventType: EventCodeEnum;
  private _data: any[];
  private _result: boolean;
  private _client: any;
  private _store: any;
  private _sourceIp: string;

  set client(value: any) {
    this._client = value;
  }

  set sourceIp(value: any) {
    this._sourceIp = value;
  }

  constructor(
    eventType: EventCodeEnum,
    store: any,
    data: any[] = [],
    result = true,
  ) {
    this._eventType = eventType;
    this._data = data;
    this._result = result;
    this._store = store;
    this._client = { id: 0, clientIdType: "", uuid: "" };
    this._sourceIp = "";
  }

  private getEventObjectResult() {
    return [
      {
        ...this.getHeader(),
        timestamp: new Date().toISOString(),
        result: this._result,
        ...this.getAudit(),
        ...this.getSource(),
        details: this._data,
      },
    ];
  }

  private getHeader() {
    return eventMnemonic.find((x) => x.eventCode == this._eventType);
  }

  private getAudit() {
    return {
      application: ConstantPid.APPLICATION,
      channel: ConstantPid.CHANNEL,
      advisorId: ConstantPid.ADVISORID,
      clientIdType: this.getClient().clientIdType,
      clientId: `${convertNumberToStringWithoutSymbols(this.getClient().id)}`,
      sessionId: this.getUuidClient(),
      transactionId:
        this.getClient().id + "-" + ConstantPid.ADVISORID + "-" + Date.now(),
      ipAddress: this.getIpAddress(),
    };
  }

  private getSource() {
    return { userAgent: navigator.userAgent };
  }

  private getClient() {
    return this._store.vinculation.client.infoClient !== undefined
      ? this._store.vinculation.client.infoClient
      : this._client;
  }

  private getUuidClient() {
    return this._store.vinculation.client.uuid !== undefined
      ? this._store.vinculation.client.uuid
      : this.getClient().uuid;
  }

  private getIpAddress() {
    return this._store.configuration.parameters.source_ip !== undefined
      ? this._store.configuration.parameters.source_ip
      : this._sourceIp;
  }

  public sendEvent() {
    return analyticService.sendEventAnalytics(this.getEventObjectResult());
  }
}
