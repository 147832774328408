import axios from "axios";
import { environment } from "../config/environment";

export default axios.create({
  baseURL: environment.url,
  headers: {
    "Content-type": "application/json",
    "x-api-key": environment.key_url || "",
  },
});
