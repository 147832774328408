import axios from "axios";
import { environment } from "../config/environment";

// Crear una instancia de axios utilizando la sintaxis correcta para axios 1.8.3
const instance = axios.create({
  baseURL: environment.url,
  headers: {
    "Content-type": "application/json",
    "x-api-key": environment.key_url || "",
  },
});

export default instance;
