import React, { useEffect, useState } from "react";
import tratamientoHtml from "../../assets/pid/html/tratamiento-datos.html";
import parametros from "../../assets/js/parametros.json";
import { ErrorModal, HeaderContent, TealiumComponent } from "../../components";
import { Col, Modal, Radio, Row } from "antd";
import { connect, useSelector } from "react-redux";
import { updateClient } from "../../store/actions";
import { clientService } from "../../services";
import { Field } from "../../models/Field";
import {
  ConstantPid,
  EventCodeEnum,
  StatusCodeEnum,
} from "../../enums/constantPid";
import { IClient } from "../../interfaces/client.interface";
import { getValueInput } from "../../utils/validation";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import useTealium from "../../components/tealium/tealium";
import IMAGES from "../../assets/img";
import { AnalyticsEvent } from "../../classes/createEvent";

function PepTerms(props: any) {
  const divRef: any = React.createRef<HTMLDivElement>();
  const navigate: any = useNavigate();
  const store = useSelector((state: any) => state);

  const { client, updClient } = props;
  const [tealium] = useTealium({
    type: "view",
    structure: {
      pagePath: window.location.pathname,
    },
  });

  const [values, setValues]: any = React.useState({
    pepRta: new Field(),
    firmaDigitalChk: new Field(),
    politicaChk: new Field(),
    autorizacionChk: new Field(),
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalPepVisible, setIsModalPepVisible] = useState(false);
  const [canContinue, setCanContinue] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [dataModal, setDataModal] = useState({ title: "", description: "" });
  const [showErrors, setShowErrors]: any = React.useState({
    codes: [],
    show: false,
  });
  const [dataStore, setDataStore] = useState(store);

  const showModal = (value: any) => {
    if (value === "firmaDigitalChk") {
      setDataModal({
        title: "Acuerdo de firma electrónica",
        description: parametros.firmaDigital,
      });
    } else if (value === "politicaChk") {
      setDataModal({
        title: "Tratamiento de datos",
        description: tratamientoHtml,
      });
    } else if (value === "autorizacionChk") {
      setDataModal({
        title: "CANALES DE CONTACTO",
        description: parametros.canalesDeContacto,
      });
    }
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const closeModalPep = () => {
    setIsModalPepVisible(false);
  };

  const validChecked = (event: any) => {
    getValueInput(event, values, setValues);
    setCanContinue(
      values.pepRta.value &&
        values.firmaDigitalChk.value &&
        values.politicaChk.value &&
        values.autorizacionChk.value,
    );
  };

  const nextContinue = () => {
    if (
      showErrors.codes.length === 0 &&
      values.pepRta.value === ConstantPid.S
    ) {
      setShowErrors({ codes: [StatusCodeEnum.CODE_313], show: true });
      return;
    }

    setShowLoading(true);
    const newClient: IClient = {
      ...client,
      sendAnality: {
        ...client.sendAnality,
        event: ["Legal"],
      },
      legal: {
        aceptaAcuerdoFirma: ConstantPid.S,
        aceptaDeclaracionTratamientoDatos: ConstantPid.S,
        autorizaCanalesContacto: ConstantPid.S,
        clienteEsPEP: ConstantPid.N,
        tributaEnExterior: ConstantPid.N,
        operacionesMonedaExtranjera: ConstantPid.N,
      },
    };

    updClient(newClient);

    const details = [
      {
        key: "aceptaAcuerdoFirma",
        value: ConstantPid.S,
      },
      {
        key: "aceptaDeclaracionTratamientoDatos",
        value: ConstantPid.S,
      },
      {
        key: "autorizaCanalesContacto",
        value: ConstantPid.S,
      },
      {
        key: "clienteEsPEP",
        value: ConstantPid.N,
      },
      {
        key: "tributaEnExterior",
        value: ConstantPid.N,
      },
      {
        key: "operacionesMonedaExtranjera",
        value: ConstantPid.N,
      },
    ];
    const analyticsEvent = new AnalyticsEvent(
      EventCodeEnum.CODE_54,
      dataStore,
      details,
    );
    analyticsEvent.sendEvent().catch(() => {
      setShowLoading(false);
    });

    navigate("/producto");
  };

  const viewModalPep = () => {
    setIsModalPepVisible(true);
  };

  return (
    <div ref={divRef}>
      {
        <HeaderContent
          title={"Necesitamos algunas autorizaciones para continuar"}
          subtitle={"Queremos hacerte la vinculación más rápida y fácil"}
        />
      }
      <div className="body-container">
        <Row className="row-item">
          <Col span={24}>
            <p className="standar-text">
              ¿Eres una persona expuesta pública o políticamente (PEP), tienes
              responsabilidades tributarias fuera de Colombia o realizas
              operaciones en moneda extrajera ?
            </p>
            <p>
              <span className="info-pep" onClick={viewModalPep}>
                {" "}
                <img src={IMAGES["infoPep"]}></img> ¿Qué es el reconocimiento
                PEP?
              </span>
            </p>
          </Col>
        </Row>

        <Row className="row-item">
          <Col span={24}>
            <Radio.Group
              className="radio-inline"
              size="large"
              onChange={validChecked}
              name="pepRta"
            >
              <Radio id="pepRtaNo" value="N">
                No
              </Radio>
              <Radio id="pepRtaSi" value="S">
                Si
              </Radio>
            </Radio.Group>
          </Col>
        </Row>

        <Row className="row-item check-acuerdo">
          <Col span={24}>
            <Checkbox
              onChange={validChecked}
              name="firmaDigitalChk"
              id="firmaDigitalChk"
            ></Checkbox>
            <span className="small-text">
              Autorizo el{" "}
              <span
                id="firmaDigitalSpan"
                className="text-link"
                onClick={() => showModal("firmaDigitalChk")}
              >
                {" "}
                acuerdo de firma electrónica
              </span>
            </span>
          </Col>
        </Row>

        <Row className="row-item">
          <Col span={24}>
            <Checkbox
              onChange={validChecked}
              name="politicaChk"
              id="politicaChk"
            ></Checkbox>
            <span className="small-text">
              Autorizo la{" "}
              <span
                id="politicaSpan"
                className="text-link"
                onClick={() => showModal("politicaChk")}
              >
                {" "}
                consulta en centrales de riesgo, operadores de seguridad social
                y el tratamiento de datos personales{" "}
              </span>
            </span>
          </Col>
        </Row>
        <Row className="row-item">
          <Col span={24}>
            <Checkbox
              onChange={validChecked}
              name="autorizacionChk"
              id="autorizacionChk"
            ></Checkbox>
            <span className="small-text">
              Autoriza el contacto a través de los{" "}
              <span
                id="autorizacionSpan"
                className="text-link"
                onClick={() => showModal("autorizacionChk")}
              >
                {" "}
                canales habilitados{" "}
              </span>
            </span>
          </Col>
        </Row>
      </div>
      <Row className="row-item">
        <Col span={24}>
          <div className="center-button">
            <focc-button
              id="btnContinuePep"
              onClick={nextContinue}
              disabled={!canContinue}
              loading={showLoading ? "on" : "off"}
            >
              Continuar
            </focc-button>
          </div>
        </Col>
      </Row>
      {/** Modal firma y politicas */}

      <Modal
        title={dataModal.title}
        open={isModalVisible}
        style={{ height: "calc(100vh - 200px)" }}
        bodyStyle={{ overflowY: "scroll" }}
        onCancel={closeModal}
        footer={[
          <focc-button
            key="btnLeidoAcepto"
            id="btnLeidoAcepto"
            onClick={closeModal}
          >
            He leído y acepto
          </focc-button>,
        ]}
      >
        <div
          className="content-modal"
          dangerouslySetInnerHTML={{ __html: dataModal.description }}
        />
      </Modal>
      <ErrorModal
        errorCode={showErrors.codes[0]}
        isVisible={showErrors.show}
      ></ErrorModal>

      <Modal
        title="¿Qué es el reconocimiento PEP?"
        open={isModalPepVisible}
        onCancel={closeModalPep}
        bodyStyle={{ overflowY: "scroll" }}
        style={{ height: "calc(100vh - 200px)" }}
        footer={[
          <focc-button
            key="btnAceptoPep"
            id="btnAceptoPep"
            onClick={closeModalPep}
          >
            Aceptar
          </focc-button>,
        ]}
      >
        <div className="content-modal content-modal-pep">
          Son PEP aquellos servidores públicos de la administración pública
          nacional y territorial, cuando tengan asignadas o delegadas funciones
          de expedición de normas, dirección general, formulación de políticas
          institucionales y adopción de planes, programas y proyectos, manejo
          directo de bienes, dineros o valores del Estado, administración de
          justicia o facultades administrativo sancionatorias También son PEP
          los particulares que tengan a su cargo la dirección o manejo de
          recursos en los movimientos o partidos políticos, al igual que los
          fideicomitentes de Patrimonios Autónomos o fideicomisos que
          administren recursos públicos. La calidad de PEP se mantendrá en el
          tiempo del ejercicio del cargo y por dos años más. <br />
          <br />
          <b>PEP Nacionales:</b> Algunos cargos catalogados como PEP
          corresponden al Presidente de la República, Vicepresidente, Ministros,
          Superintendentes, Tesoreros, Generales de las Fuerzas Militares y de
          la Policía Nacional, Gobernadores, Alcaldes, Diputados, Concejales y
          Senadores, entre otros que cumplan con las condiciones mencionadas
          anteriormente. <br />
          <br />
          <b>PEP Extranjeros:</b> Son aquellas que desempeñen funciones públicas
          destacadas en otro país. Algunos de ellos podrían ser jefes de Estado,
          jefes de Gobierno, ministros, subsecretarios o secretarios de Estado;
          congresistas o parlamentarios, miembros de tribunales supremos, altos
          funcionarios de las fuerzas armadas, entre otros que cumplan con las
          condiciones mencionadas anteriormente. <br />
          <br />
          <b>PEP de Organizaciones Internacionales:</b> Son aquellas personas
          que ejercen funciones directivas en una organización internacional
          como directores, subdirectores, miembros de juntas directivas, entre
          otros que cumplan con las condiciones mencionadas anteriormente.
          <br />
          <br />
          También se considerará como PEP a la persona con una sociedad conyugal
          de hecho o de derecho con un PEP y a familiares hasta el segundo grado
          de consanguinidad, segundo de afinidad y primero civil de un PEP.
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    client: state.vinculation.client,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updClient: (data: any) => dispatch(updateClient(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PepTerms);
