import React, { useEffect, useState } from "react";
import { Col, Radio, Row } from "antd";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorModal, HeaderContent } from "../../components";
import { Field } from "../../models/Field";
import { convertYears, updState, validObject } from "../../utils";
import { eventValid, validEventDate } from "../../utils/validation";
import { updateClient } from "../../store/actions";
import { analyticService, clientService } from "../../services";
import { IClient } from "../../interfaces/client.interface";
import {
  ConstantPid,
  EventCodeEnum,
  StatusCodeEnum,
} from "../../enums/constantPid";
import useTealium from "../../components/tealium/tealium";
import { formatDate } from "../../utils/conversions";
import { AnalyticsEvent } from "../../classes/createEvent";

function Origin(props: any) {
  const navigate: any = useNavigate();

  const today = new Date();

  const [fail, setFail] = useState(false);
  const [viewAuto, setStateViewAuto] = useState(true);
  const [viewField, setStateViewField] = useState(true);
  const { parameters, client, updClient, dataStore } = props;

  const [tealium] = useTealium({
    type: "view",
    structure: {
      pagePath: window.location.pathname,
    },
  });
  const [cities] = React.useState(parameters.ciudad);
  const [countries] = React.useState(parameters.nacionalidad);
  const [isOtherNacionality, setIsOtherNacionality] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [originGlobal, setOriginGlobal] = React.useState({
    canContinue: false,
    showLoading: false,
  });

  const [fields, setFields]: any = React.useState({
    birth_date: new Field(),
    birth_city: new Field(),
    birth_country: new Field(),
    expedition_country: new Field(),
    other_nationality: new Field(),
    nacionality: new Field(),
    birth_city_field: new Field(),
  });

  const [showErrors, setShowErrors]: any = React.useState({
    codes: [],
    show: false,
  });

  useEffect(() => {
    const inputs = document.querySelectorAll("focc-field");
    inputs.forEach((input: any) => {
      input?.addEventListener("valid", validEvent);
    });

    const expeditionCountry: HTMLFoccAutocompleteElement =
      document.querySelector("#origin_expedition_country")!;

    const birth_country: HTMLFoccAutocompleteElement = document.querySelector(
      "#origin_birth_country",
    )!;

    const birthCity: HTMLFoccAutocompleteElement =
      document.querySelector("#origin_birth_city")!;
    birthCity.addEventListener("selected", handleChange);
    birthCity.data = cities;
    const nacionality: HTMLFoccAutocompleteElement = document.querySelector(
      "#origin_nacionality",
    )!;
    if (nacionality) {
      nacionality.addEventListener("selected", handleChange);
      nacionality.data = countries;
    }
    if (expeditionCountry !== null && birth_country !== null) {
      expeditionCountry.addEventListener("selected", handleChange);
      expeditionCountry.data = countries;

      birth_country.addEventListener("selected", handleChange);
      birth_country.data = countries;
    }
  }, []);

  const validEvent = (e: any) => {
    if (eventValid(e, fields, setFields)) {
      validDate(e, () => {
        handleGlobal(validObject(fields), "canContinue");
      });
    }
  };

  const validDate = (e: any, callback: any) => {
    const { target } = e;
    const { value, name } = target;
    validEventDate(
      e,
      convertYears(value, client.infoClient.expedition) < 18,
      `#origin_${name}`,
      "Este campo debe ser al menos 18 años anterior a la fecha de expedición de la cédula",
      setFail,
      callback,
    );

    validEventDate(
      e,
      convertYears(value, formatDate(today)) > 99,
      `#origin_${name}`,
      "Debes tener entre 18 y 100 años",
      setFail,
      callback,
    );
  };

  const handleGlobal = (value: any, name: string) => {
    updState(value, name, originGlobal, setOriginGlobal);
  };

  const handleChange = (evt: any) => {
    const nacionality = fields["nacionality"];
    const birth_country = fields["birth_country"];
    const birth_city = fields["birth_city"];
    const birth_city_field = fields["birth_city_field"];
    birth_city_field.valid = false;
    birth_city.valid = false;
    nacionality.value = null;

    const { target, detail, type } = evt;
    const { name, value, checked } = target;
    const newValue =
      type === "selected" ? detail.value : !value ? checked : value;

    if (newValue !== null) {
      if (newValue.isNotTarget && newValue.isNotTarget === ConstantPid.S) {
        setShowErrors({
          ...showErrors,
          codes: [StatusCodeEnum.CODE_329],
        });
      }
    }

    const newValid =
      type === "selected"
        ? detail.valid
        : !value
        ? checked
        : value.length > 0
        ? true
        : false;

    const old = fields[name];
    old.value = newValue;

    if (type !== "input") {
      old.valid = newValid;
    }
    const newFieldsValidate = { ...fields };

    if (name === "other_nationality") {
      if (value === ConstantPid.N) {
        setIsOtherNacionality(false);
        delete newFieldsValidate.nacionality;
        nacionality.value = null;
        nacionality.valid = false;
        const nacio: any = document
          .querySelector("#origin_nacionality")
          ?.shadowRoot?.querySelector("#nacionality-only");
        if (nacio) {
          nacio.value = null;
        }
      } else {
        nacionality.value = null;
        nacionality.valid = false;
        setIsOtherNacionality(true);
      }
    }

    if (fields.other_nationality.value === "N") {
      nacionality.valid = true;
    }

    if (name === "birth_date") {
      setFail(false);
    }
    if (birth_country.value?.value === "COL") {
      setStateViewAuto(false);
      setStateViewField(true);
      birth_city_field.value = null;
      birth_city_field.valid = true;
      birth_city.valid = birth_city.value !== null;
    } else if (
      birth_country.value?.value !== "COL" &&
      birth_country.value !== null
    ) {
      setStateViewAuto(true);
      setStateViewField(false);
      birth_city.value = null;
      birth_city.valid = true;
      birth_city_field.valid =
        birth_city_field.value !== null && birth_city_field.value !== false;
    }
    updState(old, name, fields, setFields);
    handleGlobal(validObject(newFieldsValidate), "canContinue");
  };

  const nextContinue = () => {
    if (showErrors.codes.length > 0) {
      setShowErrors({ ...showErrors, show: true });
      return;
    }

    const newClient: IClient = {
      ...client,
      sendAnality: {
        ...client.sendAnality,
        event: ["InfoBirth"],
      },
      infoBirth: {
        birth_city: fields.birth_city.value?.value || "",
        birth_city_name: fields.birth_city_field.value
          ? fields.birth_city_field.value
          : fields.birth_city.value?.description,
        birth_date: fields.birth_date.value,
        birth_country: fields.birth_country.value?.value,
        birth_country_name: fields.birth_country.value?.description,
        expedition_country: fields.expedition_country.value?.value,
        expedition_country_name: fields.expedition_country.value?.description,
        nacionality: fields.nacionality.value?.value || "",
        nacionality_name: fields.nacionality.value?.description || "",
      },
    };
    updClient(newClient);

    const details = [
      {
        key: "Birth_city",
        value: fields.birth_city.value?.value,
      },
      {
        key: "uuid",
        value: client.uuid,
      },
    ];

    const analyticsEvent = new AnalyticsEvent(
      EventCodeEnum.CODE_46,
      dataStore,
      details,
    );
    analyticsEvent
      .sendEvent()
      .then()
      .catch((err) => {
        setShowErrors({
          codes: [StatusCodeEnum.CODE_599],
          show: true,
        });
        setShowLoading(false);
      });

    setShowLoading(false);
    navigate("/residencia");
  };

  return (
    <div>
      {
        <HeaderContent
          title={"Cuéntanos de dónde eres"}
          subtitle={"Es importante para nosotros"}
        />
      }
      <div className="container-padding">
        <Row className="row-margin-botton" gutter={[16, 16]}>
          <Col sm={12} xs={24}>
            <focc-field
              id="origin_birth_date"
              type="date"
              name="birth_date"
              placeholder="01/08/2000"
              fail={fail}
              onInput={handleChange}
              onValid={validEvent}
              required
            >
              Tu fecha de nacimiento
            </focc-field>
          </Col>
          <Col sm={12} xs={24}>
            <focc-autocomplete
              required
              id="origin_expedition_country"
              name="expedition_country"
              onSelected={handleChange}
              data={cities}
              find="description"
              placeholder="Ej: Colombia "
            >
              Pais de expedición de la cédula
            </focc-autocomplete>
          </Col>
          <Col sm={12} xs={24}>
            <focc-autocomplete
              required
              id="origin_birth_country"
              name="birth_country"
              onSelected={handleChange}
              data={cities}
              find="description"
              placeholder="Ej: Colombia"
            >
              País de nacimiento
            </focc-autocomplete>
          </Col>

          <Col sm={12} xs={24}>
            <div hidden={viewAuto}>
              <focc-autocomplete
                required={viewField}
                id="origin_birth_city"
                name="birth_city"
                onSelected={handleChange}
                data={cities}
                value={fields.birth_city.value?.description}
                find="description"
                placeholder="Ciudad de nacimiento"
              >
                Ciudad de nacimiento
              </focc-autocomplete>
            </div>

            <div hidden={viewField}>
              <focc-field
                required={viewAuto}
                id="origin_birth_city_field"
                name="birth_city_field"
                type="text"
                format="on"
                onBlur={handleChange}
                value={fields.birth_city_field.value}
                placeholder="Ciudad de nacimiento"
              >
                Ciudad de nacimiento
              </focc-field>
            </div>
          </Col>
        </Row>

        <Row className="row-margin-botton">
          <Col sm={12} xs={24}>
            <p className="txt-nacionality">¿Tienes otra nacionalidad?</p>
            <Radio.Group
              className="radio-flex"
              size="large"
              onChange={handleChange}
              name="other_nationality"
              id="origin_other_nationality"
              value={fields.other_nationality.value}
            >
              <Radio id="origin_nationality_no" value="N">
                No
              </Radio>
              <Radio id="origin_nationality_si" value="S">
                Si
              </Radio>
            </Radio.Group>
          </Col>
        </Row>

        <div
          className={
            isOtherNacionality
              ? "div-nacionality show-animation-container"
              : "div-nacionality"
          }
        >
          <Row className="row-margin-botton">
            <Col span={24}>
              <focc-autocomplete
                required
                id="origin_nacionality"
                name="nacionality"
                onSelected={handleChange}
                data={countries}
                find="description"
                placeholder="Nacionalidad"
              >
                Nacionalidad
              </focc-autocomplete>
            </Col>
          </Row>
        </div>
      </div>

      <div className="center-button">
        <focc-button
          id="btnLoading"
          onClick={nextContinue}
          disabled={!originGlobal.canContinue}
          loading={showLoading ? "on" : "off"}
        >
          Continuar
        </focc-button>
      </div>
      <ErrorModal
        errorCode={showErrors.codes[0]}
        isVisible={showErrors.show}
      ></ErrorModal>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    client: state.vinculation.client,
    parameters: state.configuration.parameters,
    dataStore: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updClient: (data: any) => dispatch(updateClient(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Origin);
