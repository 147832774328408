import { PRODUCT_ADD, PRODUCT_UPDATE } from "../types";

const initialState = {
  info: {},
};

const productReducer = (state: any = initialState, action: any = {}) => {
  const data = action.payload || {};

  switch (action.type) {
    case PRODUCT_ADD:
      return { ...state, info: data };
    case PRODUCT_UPDATE:
      return { ...state, info: data };
    default:
      return state;
  }
};

export default productReducer;
