import React, { useState } from "react";
import IMAGES from "../../assets/img";
function FoccSimpleCard(props: any) {
  const [open, setOpen] = useState(false);
  const [txtSeeMore, setTxtSeeMore] = useState("Ver más");

  const { title, text, img, black, dropDown } = props;

  const setDropDown = () => {
    const title = open ? "Ver más" : "Ver menos";
    setTxtSeeMore(title);
    setOpen(!open);
  };

  return (
    <div
      className={
        dropDown && dropDown.subtitle
          ? "focc-simple-card m-auto w-100"
          : "focc-simple-card m-auto"
      }
    >
      <div className="focc-simple-card-header">
        <div
          className={
            dropDown && dropDown.subtitle
              ? "focc-simple-card-title-drop-down focc-simple-card-title"
              : "focc-simple-card-title"
          }
        >
          <div className="icon">
            <img src={img} alt="" />
          </div>
          <div className="complement">
            <span> {title} </span>
            <a className={black ? "color-carbon" : ""}> {text} </a>
          </div>
        </div>
        {dropDown && dropDown.subtitle ? (
          <div>
            <a className="desktop-link" onClick={setDropDown}>
              <span>{txtSeeMore}</span>
              <img alt="" src={IMAGES[open ? "arrow-right" : "arrow-down"]} />
            </a>
            <a className="mobile-link" onClick={setDropDown}>
              <img alt="" src={IMAGES[open ? "arrow-up" : "arrow-down2"]} />
            </a>
          </div>
        ) : null}
      </div>
      {dropDown && dropDown.subtitle && open ? (
        <div className="show-animation-container focc-simple-card-drop-down">
          <h3>{dropDown.subtitle}</h3>
          <div dangerouslySetInnerHTML={{ __html: dropDown.description }}></div>
        </div>
      ) : null}
    </div>
  );
}
export default FoccSimpleCard;
