import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import erroresJson from "../../assets/js/error.json";
import IMAGES from "../../assets/img";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { resetStore } from "../../store/actions";

function ErrorModal(props: any) {
  const { errorCode, isVisible, reload, resetRedux } = props;
  const navigate: any = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(isVisible);
  const [dataError, setDataError] = useState({
    image: "error",
    title: "",
    descripction: "",
  });

  useEffect(() => {
    if (errorCode) {
      const error: any = erroresJson.find((x: any) =>
        x.codes.includes(errorCode),
      );
      setDataError(error);
    }
  }, [errorCode]);

  useEffect(() => {
    setIsModalVisible(isVisible);
  }, [isVisible]);

  const closeModal = () => {
    setIsModalVisible(false);
    resetRedux();
    if (!reload) {
      navigate("/");
    }
  };

  return (
    <div>
      <Modal
        style={{ width: "336px" }}
        open={isModalVisible}
        closable={false}
        width={336}
        footer={[
          <focc-button
            key="btnAceptoError"
            id="btnAceptoError"
            onClick={closeModal}
          >
            Aceptar
          </focc-button>,
        ]}
      >
        <div className="text-center">
          <div>
            <img src={IMAGES[dataError.image]} />
          </div>
          <div className="title-error-modal">{dataError.title}</div>
          <div className="description-error-modal">
            {dataError.descripction}
          </div>
        </div>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    client: state.vinculation.client,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetRedux: () => dispatch(resetStore()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
