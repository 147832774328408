import React from "react";
import { useNavigate } from "react-router-dom";
import IMAGES from "../../assets/img";
import { ConfirmModal } from "../../components";
import { analyticService } from "../../services";
import { AnalyticsEvent } from "../../classes/createEvent";
import { EventCodeEnum, StatusCodeEnum } from "../../enums/constantPid";
import { useSelector } from "react-redux";

function ButtonWithdrawal(props: any) {
  const labelSi = "Si. Deseo abandonar el proceso";
  const labelNo = "No. Quiero continuar mi  vinculación";

  const { client } = props;
  const navigate: any = useNavigate();
  const store = useSelector((state: any) => state);

  const [state, setState] = React.useState(false);
  const [dataStore] = React.useState(store);

  const confirm = () => {
    setState(false);
    sendEvent(true);
    navigate("/desistimiento");
  };

  const openConfirm = () => {
    setState(true);
  };

  const cancel = () => {
    setState(false);
    sendEvent(false);
  };

  const sendEvent = (res: boolean) => {
    const details = [
      {
        key: "Id",
        value: client.infoClient.id,
      },
      {
        key: "Code",
        value: StatusCodeEnum.CODE_200,
      },
      {
        key: "Response",
        value: res ? labelSi : labelNo,
      },
      {
        key: "uuid",
        value: client.uuid,
      },
    ];

    const analyticsEvent = new AnalyticsEvent(
      EventCodeEnum.CODE_60,
      dataStore,
      details,
    );
    analyticsEvent.sendEvent().catch((err) => {
      console.log("err", err);
    });
  };

  return (
    <div>
      <button className="button" onClick={() => openConfirm()}>
        {" "}
        Salir{" "}
      </button>
      <ConfirmModal
        img={IMAGES["desistimiento"]}
        question="¿Estás seguro que deseas abandonar el proceso?"
        textConfirm={labelSi}
        textCancel={labelNo}
        isVisible={state}
        onConfirm={() => confirm()}
        onCancel={() => cancel()}
      />
    </div>
  );
}

export default ButtonWithdrawal;
