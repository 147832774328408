import React from "react";

function HeaderContent({ title = "", subtitle = "", hiddenHr = false }) {
  return (
    <div>
      <div className="header-container">
        <h1 className="title">{title}</h1>
        <h3 className="subtitle">{subtitle}</h3>
      </div>
      {!hiddenHr && <hr />}
    </div>
  );
}

export default HeaderContent;
