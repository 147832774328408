import React from "react";
import { Radio } from "antd";
import { useState } from "react";
import IMAGES from "../../assets/img";
import { useNavigate } from "react-router-dom";
import { environment } from "../../config/environment";
import moment from "moment";

function ProductButton(props: any) {
  const { listProduct, setProduct, updProduct } = props;
  const navigate: any = useNavigate();

  const [idProductSelected, setIdProductSelected] = useState();

  const onChangeRadio = (e: any) => {
    const codigosifi = e.target.value;
    setIdProductSelected(codigosifi);
    setProduct(listProduct.find((x: any) => x.codigosifi === codigosifi));
  };

  const clickProduct = (product: any) => {
    const element: any = document.getElementById(
      `product_${product.codigosifi}`,
    );

    if (element) {
      element.checked = true;
    }

    onChangeRadio({ target: { value: product.codigosifi } });
  };

  const openDetails = (product: any) => {
    updProduct(product);
    navigate("/detalleProducto");
  };

  const getDaysLimit = (dateLimit: string) => {
    if (dateLimit == null || dateLimit == undefined) {
      return -1;
    }
    const dateToday = moment();
    const dateFinal = moment(
      moment(dateLimit, "DD-MM-YYYY").format("YYYY-MM-DD"),
    );
    return dateFinal.diff(dateToday, "days");
  };

  return (
    <div>
      <Radio.Group
        onChange={onChangeRadio}
        value={idProductSelected}
        className="flex-center-container"
      >
        {listProduct.map((product: any, index: number) => {
          const daysLimit = getDaysLimit(product.fechaLimite);
          return (
            <div
              key={index}
              className="product-button"
              id={`button_${product.codigosifi}`}
              onClick={() => {
                clickProduct(product);
              }}
            >
              <div className="header-card-product">
                <Radio
                  id={`product_${product.codigosifi}`}
                  value={product.codigosifi}
                />
                <img
                  alt="imagen fondo"
                  src={`${environment.cdn}/FondosLarge/${product.codigosifi}.svg`}
                />

                <div
                  className="time-limit"
                  style={
                    daysLimit > -1
                      ? { visibility: "visible" }
                      : { visibility: "hidden" }
                  }
                >
                  <div className="time-limit-subtitle">EXPIRA EN </div>
                  <div>{daysLimit + 1}</div>
                  <div className="time-limit-subtitle">Días</div>
                </div>
              </div>
              <div className="product-button-container">
                <p className="content-dark mb-20">{product.descripcionCorta}</p>
                <div className="container-flex-between mb-20">
                  <h2 className="f-size-12">Perfil de riesgo</h2>
                  <span className="tag-green">{product.perfil}</span>
                </div>
                <a
                  id={`details_${product.codigosifi}`}
                  className="content-blue"
                  onClick={() => openDetails(product)}
                >
                  {" "}
                  Conoce más del fondo <img src={IMAGES["arrow"]} alt="" />{" "}
                </a>
              </div>
            </div>
          );
        })}
      </Radio.Group>
    </div>
  );
}

export default ProductButton;
