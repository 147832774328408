import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addProduct,
  addValidation,
  updateClient,
  updateProduct,
} from "../../store/actions";
import { analyticService, productService } from "../../services";
import {
  ErrorModal,
  HeaderContent,
  ProductButton,
  ConfirmProduct,
} from "../../components";
import useTealium from "../../components/tealium/tealium";
import { EventCodeEnum, StatusCodeEnum } from "../../enums/constantPid";
import { AnalyticsEvent } from "../../classes/createEvent";

function Product(props: any) {
  const divRef: any = React.createRef<HTMLDivElement>();
  const [tealium] = useTealium({
    type: "view",
    structure: {
      pagePath: window.location.pathname,
    },
  });

  const {
    client,
    addProduct,
    updClient,
    addValidation,
    updProduct,
    dataStore,
  } = props;

  const [listProduct, setListProduct] = useState([]);
  const [productSelected, setProductSelected] = useState({
    codigosifi: null,
    urlReglamento: "",
    urlProspecto: "",
    producto: "",
    templateCorreo: "",
  });
  const [showErrors, setShowErrors]: any = React.useState({
    codes: [],
    show: false,
  });

  useEffect(() => {
    let isSubscribed = true;
    addProduct({});
    productService
      .getProducts()
      .then((response: any) => {
        setListProduct(response.data.fondos);
      })
      .catch(() => {
        const details = [
          {
            key: "uuid",
            value: client.uuid,
          },
          {
            key: "Code",
            value: StatusCodeEnum.CODE_500,
          },
          {
            key: "Id",
            value: client.infoClient.id,
          },
          {
            key: "FullName",
            value: `${client.infoClient.names} ${client.infoClient.surnames}`,
          },
          {
            key: "Phone",
            value: client.infoClient.phone,
          },
          {
            key: "Email",
            value: client.infoClient.email,
          },
          {
            key: "Path",
            value: window.location.pathname,
          },
        ];
        const analyticsEventFail = new AnalyticsEvent(
          EventCodeEnum.CODE_61,
          dataStore,
          details,
        );
        analyticsEventFail
          .sendEvent()
          .then()
          .catch((err) => {
            console.log("err", err);
          });

        setShowErrors({
          codes: [StatusCodeEnum.CODE_500],
          show: true,
        });
      });
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isSubscribed = false;
    };
  }, []);

  const getProductSelected = (product: any) => {
    setProductSelected(product);
    const politicaProdChk: any = document.getElementById("politicaProdChk");
    if (politicaProdChk !== null && politicaProdChk.checked) {
      politicaProdChk.click();
    }
  };

  return (
    <div ref={divRef}>
      {
        <HeaderContent
          title={"¿Cómo deseas construir tu meta?"}
          subtitle={"Tenemos un producto ideal para ti"}
        />
      }

      <ProductButton
        listProduct={listProduct}
        setProduct={getProductSelected}
        updProduct={updProduct}
      ></ProductButton>

      <ConfirmProduct
        product={productSelected}
        client={client}
        updProduct={updProduct}
        updClient={updClient}
        addValidation={addValidation}
      ></ConfirmProduct>

      <ErrorModal
        errorCode={showErrors.codes[0]}
        isVisible={showErrors.show}
      ></ErrorModal>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    client: state.vinculation.client,
    dataStore: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addProduct: (data: any) => dispatch(addProduct(data)),
    updProduct: (data: any) => dispatch(updateProduct(data)),
    updClient: (data: any) => dispatch(updateClient(data)),
    addValidation: (data: any) => dispatch(addValidation(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
