import { CONFIGURATION_ADD, CONFIGURATION_FAIL_ADD } from "../types";

const initialState = {
  parameters: {},
};

const configurationReducer = (state: any = initialState, action: any = {}) => {
  const data = action.payload || {};

  switch (action.type) {
    case CONFIGURATION_ADD:
      return { ...state, parameters: data };
    case CONFIGURATION_FAIL_ADD:
      return { ...state, fail: data };
    default:
      return state;
  }
};

export default configurationReducer;
