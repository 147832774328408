import React, { useEffect, useState, useRef } from "react";
import { Modal } from "antd";
import IMAGES from "../../assets/img";
import { connect } from "react-redux";
import { resetStore } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { environment } from "../../config/environment";

function TimeOutModal(props: any) {
  const { resetRedux } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const validateTimerRef = useRef<any>();
  const navigate: any = useNavigate();

  useEffect(() => {
    addEventsDocument();
    startTime();
    return () => clearTimeout(validateTimerRef.current);
  }, []);

  const addEventsDocument = () => {
    document.addEventListener("mousemove", () => {
      resetTime();
    });
    document.addEventListener("mousedown", () => {
      resetTime();
    });
    document.addEventListener("keypress", () => {
      resetTime();
    });
    document.addEventListener("DOMMouseScroll", () => {
      resetTime();
    });
    document.addEventListener("mousewheel", () => {
      resetTime();
    });
    document.addEventListener("touchmove", () => {
      resetTime();
    });
    document.addEventListener("MSPointerMove", () => {
      resetTime();
    });
  };

  const startTime = () => {
    validateTimerRef.current = setInterval(() => {
      setIsModalVisible(true);
    }, environment.inactivityTime);
  };

  const resetTime = () => {
    clearTimeout(validateTimerRef.current);
    startTime();
  };

  const closeModal = () => {
    resetRedux();
    setIsModalVisible(false);
    navigate("/");
  };

  return (
    <div>
      <Modal
        className="focc-timeOut-modal"
        style={{ width: "336px" }}
        open={isModalVisible}
        closable={false}
        width={336}
        footer={[
          <focc-button
            key="btnAceptoError"
            id="btnAceptoError"
            onClick={closeModal}
          >
            Aceptar
          </focc-button>,
        ]}
      >
        <div className="text-center">
          <div>
            <img src={IMAGES["inactividad"]} />
          </div>
          <div className="title-error-modal">¡Lo sentimos!</div>
          <div className="description-error-modal">
            Has excedido el tiempo sin actividad. Debes reiniciar el proceso.
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    client: state.vinculation.client,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetRedux: () => dispatch(resetStore()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeOutModal);
