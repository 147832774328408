import React from "react";
import { connect } from "react-redux";
import {
  addValidation,
  updateClient,
  updateProduct,
} from "../../store/actions";
import { ConfirmProduct, FoccSimpleCard } from "../../components";
import useTealium from "../../components/tealium/tealium";
import IMAGES from "../../assets/img";
import { environment } from "../../config/environment";
function ProductDetails(props: any) {
  const [tealium] = useTealium({
    type: "view",
    structure: {
      pagePath: window.location.pathname,
    },
  });
  const { product, client, updClient, addValidation, updProduct } = props;
  return (
    <div>
      {product.fechaLimite ? (
        <div className="time-limit-detail">Tiempo limitado</div>
      ) : null}
      <section className="flex-simple-center p-29">
        <h1 className="f-size-18">{product.producto}</h1>
        <p className="content-dark text-left f-size-13">
          {product.descripcionLarga}
        </p>
        <hr className="m-0" />
        <div className="container-flex-between mb-20">
          <h2 className="mb-0">Perfil de riesgo</h2>
          <span className="tag-green">{product.perfil}</span>
        </div>
        <section className="card-blue">
          <div className="card-blue-item">
            <h3>{product.montoMinimo}</h3>
            <span>Monto mínimo</span>
          </div>
          <div className="card-blue-item borde">
            <h3>{product.tiempo}</h3>
            <span>{product.tituloTiempo}</span>
          </div>
        </section>
        <FoccSimpleCard
          title="Pacto de permanencia"
          text={product.pactoDePertenencia}
          img={IMAGES["pactoPertenencia"]}
        ></FoccSimpleCard>
        <img
          alt="imagen fondo"
          className="m-auto detail-fondo-img"
          width="120"
          src={`${environment.cdn}/FondosEmail/img-fondo-${product.codigosifi}.svg`}
        />
        <h3>Tu capital lo invertimos en:</h3>
        <p className="content-dark mb-20 f-size-13">{product.inversionEn}</p>
        <hr className="m-0" />
        <h2 className="text-left">Costos</h2>
        <div className="d-flex-column">
          <FoccSimpleCard
            black
            title="Administración"
            text={product.costos.administracion.title}
            img={IMAGES["administracion"]}
            dropDown={product.costos.administracion}
          ></FoccSimpleCard>
          <FoccSimpleCard
            black
            title="Retiro anticipado"
            text={product.costos.retiroAnticipado.title}
            img={IMAGES["retiroAnticipado"]}
            dropDown={product.costos.retiroAnticipado}
          ></FoccSimpleCard>
        </div>
      </section>
      <ConfirmProduct
        product={product}
        client={client}
        updProduct={updProduct}
        updClient={updClient}
        addValidation={addValidation}
      ></ConfirmProduct>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    client: state.vinculation.client,
    product: state.product.info,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updProduct: (data: any) => dispatch(updateProduct(data)),
    updClient: (data: any) => dispatch(updateClient(data)),
    addValidation: (data: any) => dispatch(addValidation(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
