import { CLIENT_ADD, CLIENT_UPDATE, VALIDATION_ADD } from "../types";

const initialState = {
  client: {},
  validations: {},
};

const clientReducer = (state: any = initialState, action: any = {}) => {
  const data = action.payload || {};

  switch (action.type) {
    case CLIENT_ADD:
      return { ...state, client: data };

    case CLIENT_UPDATE:
      return { ...state, client: data };

    case VALIDATION_ADD:
      return { ...state, validations: data };

    default:
      return state;
  }
};

export default clientReducer;
