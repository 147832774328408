import React, { useEffect, useState } from "react";
import { ConstantPid, PathNames } from "../../enums/constantPid";
import { ValidOtp } from "../../interfaces/ValidOtp";
import { clientService, otpService } from "../../services";
import { setClientOtp, updState } from "../../utils";
import { convertPhoneForHash } from "../../utils/conversions";
import ErrorModal from "../error-modal/error-modal";
import useTealium from "../tealium/tealium";

function Otp(props: any) {
  const divRef: any = React.createRef<HTMLDivElement>();
  const {
    setOtp,
    timeOtp,
    phone,
    client,
    uuid,
    updClient,
    descOtp,
    setValueOtp,
    sourceIp,
  } = props;

  const [tealium] = useTealium({
    type: "view",
    structure: {
      pagePath: PathNames.OTP,
    },
  });

  // State to save the otp value
  const [values, setValues]: any = useState();

  // Save timer response (whether to terminate or not)
  const [finishTime, setFinishTime]: any = useState();

  const [triedSMS, setTriedSMS] = useState(false);
  const [triedMAIL, setTriedMAIL] = useState(false);

  const [fails, setFails] = useState(0);

  const [disableResendMsn, setDisableResendMsn] = useState(true);

  const [disableResendMail, setDisableResendMail] = useState(true);

  const [showErrors, setShowErrors]: any = React.useState({
    codes: [],
    show: false,
  });

  // Continue button state
  const [globalOtp, setGlobalOtp] = React.useState({
    canContinue: false,
    showLoading: false,
    errorOtp: false,
  });

  // btn states manager
  const handleGlobal = (value: any, name: string) => {
    updState(value, name, globalOtp, setGlobalOtp);
  };

  useEffect(() => {
    let isSubscribed = true;
    const input: HTMLFoccOtpInputElement =
      document.querySelector("focc-otp-input")!;
    const domTimer: HTMLFoccCountDownElement =
      document.querySelector("focc-count-down")!;
    input?.addEventListener("completeOtp", validEvent);
    domTimer?.addEventListener("finishTime", timeOutFinish);
    return () => {
      isSubscribed = false;
    };
  }, [timeOtp, finishTime]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDisableResendMsn(false);
    }, 90000);
    return () => {
      clearTimeout(timerId);
      clearTimeout(timerId);
    };
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDisableResendMail(false);
    }, 30000);
    return () => {
      clearTimeout(timerId);
      clearTimeout(timerId);
    };
  }, []);

  const validEvent = (e: any) => {
    const { value, valid } = e.detail;
    if (valid && !finishTime) {
      setValues(value);
      setValueOtp(value);
      handleGlobal(valid, "canContinue");
    } else {
      if (globalOtp.canContinue) {
        handleGlobal(false, "canContinue");
      }
    }
  };

  const validOtp = () => {
    if (finishTime) {
      setShowErrors({
        codes: [315],
        show: true,
      });
    } else {
      executeOtp();
    }
  };

  const executeOtp = () => {
    handleGlobal(true, "showLoading");
    const surnames = client.surnames.split(" ");
    const requestOTP: ValidOtp = {
      client: {
        id: `${client.id}`,
        typeId: client.clientIdType,
        primaryLastName: `${surnames[0]}`,
        cellPhoneNumber: `${client.phone}`,
      },
      otpInfo: {
        advisor: "",
        otpValue: values,
        uuid: uuid,
        validationType: false,
        desc: descOtp,
        sessionId: uuid,
        channel: "PID App Auto",
        transactionId:
          client.id + "-" + ConstantPid.ADVISORID + "-" + Date.now(),
        ipAddress: sourceIp,
        userAgent: navigator.userAgent,
      },
      isAuto: true,
    };

    otpService
      .validateOtp(requestOTP)
      .then((response: any) => {
        const { pidResponse } = response.data;
        const { code } = pidResponse;
        if (code && code === 212) {
          const domTimer: any = document.querySelector("focc-count-down");
          domTimer?.stop();
          setGlobalOtp({
            ...globalOtp,
            errorOtp: false,
            showLoading: false,
            canContinue: true,
          });
          setOtp(values);

          updClient({
            ...client,
            otpValue: values,
          });
        } else {
          setFails(fails + 1);
          setGlobalOtp({
            errorOtp: true,
            showLoading: false,
            canContinue: false,
          });
          if (fails >= 2) {
            setShowErrors({
              codes: [315],
              show: true,
            });
          }
        }
      })
      .catch((e) => {
        setGlobalOtp({
          errorOtp: true,
          showLoading: false,
          canContinue: false,
        });
      });
  };

  const timeOutFinish = () => {
    setFinishTime(true);
    setDisableResendMsn(triedSMS);
    handleGlobal(false, "canContinue");
    if (triedSMS /*&& triedMAIL*/) {
      setShowErrors({
        codes: [315],
        show: true,
      });
    }
  };

  const resendOtp = (typeResend: "sms" | "mail") => {
    resetInputOtp();
    if (typeResend === "mail" && !disableResendMail && !triedMAIL) {
      setTriedMAIL(true);
      setDisableResendMail(true);
      setDisableResendMsn(true);
      callServiceClientOTP("EMAIL");
      if (!disableResendMail && !triedMAIL) {
        setTimeout(() => {
          setDisableResendMail(false);
        }, 30000);
      }
    } else if (typeResend === "sms" && !disableResendMsn && !triedSMS) {
      setTriedSMS(true);
      setDisableResendMsn(true);
      setDisableResendMail(true);
      callServiceClientOTP("SMS");
      if (!disableResendMail && !triedMAIL) {
        setTimeout(() => {
          setDisableResendMail(false);
        }, 30000);
      }
    } else {
      setDisableResendMsn(true);
      setDisableResendMail(true);
    }
  };

  const resetInputOtp = () => {
    setFinishTime(false);
    const domTimer: HTMLFoccCountDownElement =
      document.querySelector("focc-count-down")!;
    domTimer?.stop();
    domTimer?.reset();
    handleGlobal(timeOtp, "timer");
    const input: HTMLFoccOtpInputElement =
      document.querySelector("focc-otp-input")!;
    input?.reset();
  };

  const callServiceClientOTP = (type: "SMS" | "EMAIL") => {
    const data: any = setClientOtp(client, uuid, type, true);
    clientService.validateClient(data);
  };

  return (
    <div ref={divRef} className="container">
      <div className="flex-simple-center">
        <h1 className="title">Introduce tu código de seguridad</h1>
        <span className="content">
          Hemos enviado a tu celular {convertPhoneForHash(phone)} un código de
          seguridad de 8 dígitos
        </span>
        <span className="content-light">
          El código tiene{" "}
          <focc-count-down
            class="content-light"
            duration={90}
            onFinishTime={timeOutFinish}
          ></focc-count-down>{" "}
          min de validez
        </span>
        <focc-otp-input
          fail={globalOtp.errorOtp}
          onCompleteOtp={validEvent}
          class="m-24"
          fields={8}
          value={"3213"}
        ></focc-otp-input>
        <a
          id="focc-resend-msn"
          onClick={() => {
            resendOtp("sms");
          }}
          className="content-blue m-10"
          {...(disableResendMsn && { disabled: true } && { hidden: true })}
        >
          Recibir nuevo código
        </a>
        <a
          hidden={true}
          id="focc-resend-mail"
          onClick={() => {
            resendOtp("mail");
          }}
          className="content-blue m-10"
          {...(disableResendMail && { disabled: true })}
        >
          Solicitar correo electrónico
        </a>
      </div>
      <div className="center-button">
        <focc-button
          id="btnLoading"
          onClick={validOtp}
          loading={globalOtp.showLoading ? "on" : "off"}
          disabled={!globalOtp.canContinue}
        >
          Validar código
        </focc-button>
        <ErrorModal
          errorCode={showErrors.codes[0]}
          isVisible={showErrors.show}
        ></ErrorModal>
      </div>
    </div>
  );
}

export default Otp;
