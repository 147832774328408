import HeaderContent from "./headerContent/header-content";
import ProductButton from "./product-button/ProductButton";
import ErrorModal from "./error-modal/error-modal";
import ConfirmModal from "./confirm-modal/confirm-modal";
import ButtonWithdrawal from "./button-withdrawal/button-withdrawal";
import TealiumComponent from "./tealium/tealium";
import ConfirmProduct from "./confirm-product/confirmProduct";
import FoccSimpleCard from "./focc-simple-card/foccSimpleCard";
import { Alert } from "./alert/alert";

export {
  HeaderContent,
  ProductButton,
  ErrorModal,
  ConfirmModal,
  ButtonWithdrawal,
  TealiumComponent,
  ConfirmProduct,
  FoccSimpleCard,
  Alert,
};
