export class Field {
  value: string | null;
  valid: boolean;
  description?: string;
  id?: string;
  isNotTarget?: string;

  constructor(value?: string, valid?: boolean) {
    this.value = value || null;
    this.valid = valid || false;
  }

  clear() {
    this.value = null;
    this.valid = false;
    this.description = "";
    this.id = "";
    this.isNotTarget = "";
  }
}
