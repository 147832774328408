const validObject = (object: any): boolean => {
  let cont = 0;
  const list = Object.values(object);
  list.forEach((obj: any) => {
    cont = obj.valid ? cont + 1 : cont;
  });
  return cont === list.length;
};

const updState = (
  value: any,
  name: string,
  hookModel: any,
  hookFunction: any,
) => {
  const newValues = {
    ...hookModel,
    [name]: value,
  };
  hookFunction(newValues);
};

const eventValid = (e: any, values: any, setValues: any): boolean => {
  let save = false;
  const { detail } = e;
  if (detail) {
    const old = values[detail.name];
    if (old && old.valid !== detail.valid) {
      old.valid = detail.valid;
      updState(old, detail.name, values, setValues);
      save = true;
    }
  }
  return save;
};

const getValueInput = (event: any, values: any, setValues: any): void => {
  const { target } = event;
  const { name, value, checked } = target;
  const old = values[name];
  old.value = value;
  if (!value) {
    old.value = checked;
  }
  updState(old, name, values, setValues);
};

const validEventDate = (
  event: any,
  condition: any,
  query: any,
  msn: string,
  eventFail: any,
  callback: any,
) => {
  const { detail, target } = event;
  if (
    detail.valid &&
    target?.attributes?.getNamedItem("type")?.value === "date"
  ) {
    if (condition) {
      const input: HTMLFoccFieldElement = document.querySelector(`${query}`)!;
      input?.hasError(msn);
      eventFail(true);
    }
  }
  callback();
};

const validateSpamEmail = (
  event: any,
  condition: any,
  query: any,
  msn: string,
  eventFail: any,
  callback: any,
) => {
  const { detail, target } = event;
  if (
    detail.valid &&
    target?.attributes?.getNamedItem("type")?.value === "email"
  ) {
    if (condition) {
      const input: HTMLFoccFieldElement = document.querySelector(`${query}`)!;
      input?.hasError(msn);
      eventFail(true);
    }
  }
  callback();
};

const validatMailEquality = (
  event: any,
  condition: any,
  query: any,
  msn: string,
  eventFail: any,
  callback: any,
) => {
  if (condition) {
    const input: HTMLFoccFieldElement = document.querySelector(`${query}`)!;
    input?.hasError(msn);
    eventFail(true);
  }

  const { detail, target } = event;
  if (
    detail.valid &&
    target?.attributes?.getNamedItem("type")?.value === "email"
  ) {
    if (condition) {
      const input: HTMLFoccFieldElement = document.querySelector(`${query}`)!;
      input?.hasError(msn);
      eventFail(true);
    }
    eventFail(false);
  }
  callback();
};

const ValidateEmailDB = (
  event: any,
  condition: any,
  query: any,
  msn: string,
  eventFail: any,
  callback: any,
) => {
  if (condition) {
    const input: HTMLFoccFieldElement = document.querySelector(`${query}`)!;
    input?.hasError(msn);
    eventFail(true);
  }
  callback();
};

export {
  validObject,
  updState,
  eventValid,
  getValueInput,
  validEventDate,
  validateSpamEmail,
  ValidateEmailDB,
  validatMailEquality,
};
