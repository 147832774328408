import React, { useState, useEffect } from "react";
import { Modal } from "antd";

function ConfirmModal(props: any) {
  const {
    isVisible,
    img,
    question,
    textConfirm,
    textCancel,
    onConfirm,
    onCancel,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(isVisible);

  useEffect(() => {
    setIsModalVisible(isVisible);
  }, [isVisible]);

  const closeModal = () => {
    setIsModalVisible(false);
    onCancel();
  };

  return (
    <div>
      <Modal
        className="focc-confirm-modal"
        style={{ width: "336px" }}
        open={isModalVisible}
        closable={false}
        width={336}
        footer={[
          <focc-button key="btnCancel" id="btnCancel" onClick={closeModal}>
            {textCancel || "No"}
          </focc-button>,
          <button
            key="btnConfirm"
            className="button"
            id="btnConfirm"
            onClick={onConfirm}
          >
            {textConfirm || "Si"}
          </button>,
        ]}
      >
        <div className="text-center">
          <div>
            <img src={img} />
          </div>
          <h3 className="title-modal">{question}</h3>
        </div>
      </Modal>
    </div>
  );
}

export default ConfirmModal;
