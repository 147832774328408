import { useDebugValue, useEffect, useState } from "react";
import { useWebSocket } from "../../providers/websocketProvider.jsx";

interface Itealium {
  type: string;
  structure: {
    pagePath: string;
    sessionUID?: string;
  };
}

const useTealium = (initialValue: Itealium) => {
  useDebugValue("site hooks statistics");
  const { socketId } = useWebSocket();
  const [tealium]: any = useState(setUuid(initialValue, socketId));
  useEffect(() => {
    if (
      tealium &&
      typeof tealium === "object" &&
      typeof tealium.type === "string"
    ) {
      applyTealium(tealium);
    }
  }, [tealium]);
  return [tealium];
};

const setUuid = (initialValue: Itealium, uuid: string) => {
  return {
    ...initialValue,
    structure: {
      ...initialValue.structure,
      sessionUID: uuid,
    },
  };
};

export const applyTealium = (tealium: any) => {
  if (window.utag && typeof window.utag[tealium.type] === "function") {
    const currentStructure: any = {};
    for (const property in tealium.structure) {
      currentStructure[property] = tealium.structure[property];
    }
    window.utag[tealium.type](currentStructure);
  }
};

export default useTealium;
