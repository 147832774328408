import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorModal, HeaderContent } from "../../components";
import useTealium from "../../components/tealium/tealium";
import { EventCodeEnum, StatusCodeEnum } from "../../enums/constantPid";
import { IClient } from "../../interfaces/client.interface";
import { Field } from "../../models/Field";
import { analyticService, clientService } from "../../services";
import { updateClient } from "../../store/actions";
import { updState, validObject } from "../../utils";
import { AnalyticsEvent } from "../../classes/createEvent";

function Residence(props: any) {
  const navigate: any = useNavigate();
  const { parameters, client, updClient, dataStore } = props;
  const [tealium] = useTealium({
    type: "view",
    structure: {
      pagePath: window.location.pathname,
    },
  });
  const [cities] = React.useState(parameters.ciudad);
  const [showErrors, setShowErrors]: any = React.useState({
    codes: [],
    show: false,
  });
  const [values, setValues]: any = React.useState({
    current_address: new Field(),
    current_address_complement: new Field(),
    current_department: new Field(),
    current_city: new Field(),
  });

  const [identityGlobal, setIdentityGlobal] = React.useState({
    canContinue: false,
    showLoading: false,
  });

  const handleGlobal = (value: any, name: string) => {
    updState(value, name, identityGlobal, setIdentityGlobal);
  };

  const handleChange = (evt: any) => {
    const { detail, type } = evt;
    const { valid } = detail;
    if (type === "selected") {
      const old_current_city = values["current_city"];
      old_current_city.value = valid
        ? detail.value?.value.substring(2, detail.value?.value.length)
        : null;
      old_current_city.valid = valid;
      old_current_city.description = valid && detail.value?.description;

      const old_current_department = values["current_department"];
      old_current_department.value = valid
        ? detail.value?.value.substring(0, 2)
        : null;
      old_current_department.valid = valid;

      setValues({
        ...values,
        ["current_city"]: old_current_city,
        ["current_department"]: old_current_department,
      });
    } else {
      const old_current_address = values["current_address"];
      old_current_address.value = detail.address;
      old_current_address.valid = valid;

      const old_current_address_complement =
        values["current_address_complement"];
      old_current_address_complement.value = detail.complement;
      old_current_address_complement.valid = valid;

      setValues({
        ...values,
        ["current_address"]: old_current_address,
        ["current_address_complement"]: old_current_address_complement,
      });
    }
    handleGlobal(validObject(values), "canContinue");
  };

  useEffect(() => {
    let isSubscribed = true;
    const autocomplete: HTMLFoccAutocompleteElement =
      document.querySelector("focc-autocomplete")!;
    autocomplete.data = cities;
    autocomplete?.addEventListener("selected", handleChange);

    const address: HTMLFoccAddressFieldElement =
      document.querySelector("focc-address-field")!;
    address?.addEventListener("completeAddress", handleChange);

    return () => {
      isSubscribed = false;
    };
  }, []);

  const addClientRdx = () => {
    const person: IClient = {
      ...client,
      sendAnality: {
        ...client.sendAnality,
        event: ["InfoCurrent"],
      },
      infoCurrent: {
        current_address: values["current_address"].value,
        current_address_complement: values["current_address_complement"].value,
        current_department: values["current_department"].value,
        current_city: values["current_city"].value,
        current_city_name: values["current_city"].description,
      },
    };
    handleGlobal(true, "showLoading");
    updClient(person);

    const details = [
      {
        key: "Current_city",
        value: values["current_city"].value,
      },
      {
        key: "Current_department",
        value: values["current_department"].value,
      },
      {
        key: "Current_address",
        value: values["current_address"].value,
      },
      {
        key: "uuid",
        value: client.uuid,
      },
    ];

    const analyticsEvent = new AnalyticsEvent(
      EventCodeEnum.CODE_47,
      dataStore,
      details,
    );
    analyticsEvent
      .sendEvent()
      .then()
      .catch((err) => {
        setShowErrors({
          codes: [StatusCodeEnum.CODE_599],
          show: true,
        });
        handleGlobal(false, "showLoading");
      });

    navigate("/finanzas");
    handleGlobal(false, "showLoading");
  };

  return (
    <div>
      {
        <HeaderContent
          title={"Cuéntanos dónde vives"}
          subtitle={"Queremos conocerte mejor"}
        />
      }
      <div className="body-container">
        <focc-autocomplete
          required
          id="current_city"
          name="current_city"
          data={cities}
          onSelected={handleChange}
          find="description"
          placeholder="Ej. Medellin"
        >
          Ciudad de residencia
        </focc-autocomplete>
        <focc-address-field
          id="current_address"
          onCompleteAddress={handleChange}
        ></focc-address-field>
      </div>
      <div className="center-button">
        <focc-button
          id="btnLoading"
          onClick={addClientRdx}
          loading={identityGlobal.showLoading ? "on" : "off"}
          disabled={!identityGlobal.canContinue}
        >
          Continuar
        </focc-button>
        <ErrorModal
          errorCode={showErrors.codes[0]}
          isVisible={showErrors.show}
        ></ErrorModal>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    client: state.vinculation.client,
    parameters: state.configuration.parameters,
    dataStore: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updClient: (data: any) => dispatch(updateClient(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Residence);
