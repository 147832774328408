import { preLoaderService } from "./preloader/pre-loader-service";
import clientService from "./client/client-service";
import productService from "./product/product-service";
import otpService from "./otp/otp-service";
import financeService from "./finance/finance-service";
import parameterService from "./parameters/parameters-service";
import investmentService from "./investment/investment-service";
import captchaService from "./captcha/captcha-service";
import analyticService from "./analytics/analytic-service";
import maintenanceService from "./maintenance/maintenance-service";

export {
  preLoaderService,
  clientService,
  productService,
  otpService,
  financeService,
  parameterService,
  investmentService,
  captchaService,
  analyticService,
  maintenanceService,
};
