import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import allReducers from "./reducers";
import { RESET_STORE } from "./types";

const initialState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk];

const rootReducer = (state: any, action: any) => {
  const { configuration } = state;
  if (action.type === RESET_STORE) {
    state = { ...initialState, configuration };
  }

  return allReducers(state, action);
};

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware)),
);

export default store;
