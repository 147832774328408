import { Col, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import IMAGES from "../../assets/img";
import { HeaderContent } from "../../components";
import { analyticService } from "../../services";
import { resetStore } from "../../store/actions";
import { updState } from "../../utils";
import { AnalyticsEvent } from "../../classes/createEvent";
import { EventCodeEnum, StatusCodeEnum } from "../../enums/constantPid";

type question = {
  question: string;
  open: boolean;
  selected: boolean;
};

function WithDrawal(props: any) {
  const labelO = "No hubo selección por parte del usuario";
  const navigate: any = useNavigate();
  const { parameters, resetRedux, client, dataStore } = props;
  const [questions, setQuestions] = React.useState<question[]>(
    parameters.desistimiento,
  );

  const [response, setResponse] = React.useState<any>();
  const [answer, setAnswer] = React.useState<any>();

  const [withdrawalGlobal, setWithdrawalGlobal] = React.useState({
    disabled: true,
    showLoading: false,
  });

  const changeQuestion = (event: any) => {
    const { target } = event;
    questions.forEach((x) => (x.selected = x.question === target.value));
    const selected = questions.find((x) => x.question === target.value);
    updState(selected?.open, "disabled", withdrawalGlobal, setWithdrawalGlobal);
    setQuestions([...questions]);
    setResponse(selected?.question);
  };

  const finishWithdrawal = () => {
    updState(true, "showLoading", withdrawalGlobal, setWithdrawalGlobal);

    const details = [
      {
        key: "Id",
        value: client.infoClient.id,
      },
      {
        key: "Code",
        value: StatusCodeEnum.CODE_200,
      },
      {
        key: "Answer",
        value: answer,
      },
      {
        key: "Response",
        value: response,
      },
      {
        key: "uuid",
        value: client.uuid,
      },
    ];

    const analyticsEvent = new AnalyticsEvent(
      EventCodeEnum.CODE_61,
      dataStore,
      details,
    );
    analyticsEvent.sendEvent().catch((err) => {
      console.log("err", err);
    });

    reset();
  };

  const validEvent = (e: any) => {
    const { target } = e;
    updState(
      target.value.length === 0,
      "disabled",
      withdrawalGlobal,
      setWithdrawalGlobal,
    );
    setAnswer(target.value);
  };

  const reset = () => {
    navigate("/");
    resetRedux();
  };

  return (
    <div>
      <HeaderContent
        hiddenHr
        title={"¡Cuéntanos!"}
        subtitle={"Nos gustaría conocer tu opinión con respecto al producto"}
      />

      <div className="body-container">
        <Row className="row-item">
          <Radio.Group
            className="focc-radio-group"
            size="large"
            onChange={changeQuestion}
            name="questionWithDrawal"
          >
            {questions.map((q: question, i: number) => (
              <div key={i + "question"}>
                <Radio id={i + "question"} value={q.question}>
                  {q.question}
                </Radio>
                <br />
                {q.open && q.selected && (
                  <focc-text-area
                    onBlur={validEvent}
                    onInput={validEvent}
                    max={250}
                    id={"question-text-area-" + i}
                    name={"question-text-area-" + i}
                    placeholder="Escribe aquí tu comentario"
                    required
                  ></focc-text-area>
                )}
              </div>
            ))}
          </Radio.Group>
        </Row>
      </div>
      <div className="container-flex">
        <focc-button
          id="btnLoading"
          disabled={withdrawalGlobal.disabled}
          onClick={finishWithdrawal}
          loading={withdrawalGlobal.showLoading ? "on" : "off"}
        >
          Enviar
        </focc-button>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    parameters: state.configuration.parameters,
    client: state.vinculation.client,
    dataStore: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetRedux: () => dispatch(resetStore()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithDrawal);
