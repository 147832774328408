const convertNumber = (value: string): number => {
  return value ? parseFloat(value.split(".").join("")) : -1;
};

const convertNumberToStringWithoutSymbols = (value: any) => {
  // Eliminar todos los caracteres no numéricos, puntos y símbolo de dolar usando una expresión regular
  return String(value).replace(/[$.,]/g, "").replace(/[^\d]/g, "");
};

const cleanDate = (date: string) => {
  return date.replace(/(\d+[/])(\d+[/])/, "$2$1");
};

const convertPhoneForHash = (value: any): string => {
  value = value.toString();
  return value.replace(value.substring(6, 0), "*".repeat(6));
};

const convertYears = (date: string, otherDate?: string) => {
  date = date.replace(/(\d+[/])(\d+[/])/, "$2$1");
  if (otherDate) {
    otherDate = otherDate.replace(/(\d+[/])(\d+[/])/, "$2$1");
  }
  const hoy = otherDate ? new Date(otherDate) : new Date();
  const cumpleanos = new Date(date);
  let edad = hoy.getFullYear() - cumpleanos.getFullYear();
  const m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
  }

  return edad;
};

const padTo2Digits = (num: any) => {
  return num.toString().padStart(2, "0");
};

const formatDate = (date: Date) => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
};

export {
  convertNumber,
  convertPhoneForHash,
  convertYears,
  cleanDate,
  formatDate,
  convertNumberToStringWithoutSymbols,
};
