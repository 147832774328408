import http from "../http-common";

const validateClient = (data: any) => {
  return http.post("/validarIdentidadAuto", data);
};

const existEmails = (data: any) => {
  return http.post("/correo-cliente-analitica-auto", data);
};

export default {
  validateClient,
  existEmails,
};
