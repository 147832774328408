import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Modal } from "antd";
import { addClient } from "../../store/actions";
import { clientService, captchaService } from "../../services";
import { updState, validObject } from "../../utils";
import {
  cleanDate,
  convertNumber,
  convertNumberToStringWithoutSymbols,
} from "../../utils/conversions";
import { Field } from "../../models/Field";
import {
  ConstantPid,
  EventCodeEnum,
  StatusCodeEnum,
} from "../../enums/constantPid";
import {
  eventValid,
  validEventDate,
  validateSpamEmail,
  ValidateEmailDB,
  validatMailEquality,
} from "../../utils/validation";
import { useNavigate } from "react-router-dom";
import { ErrorModal, HeaderContent, Alert } from "../../components";
import useTealium from "../../components/tealium/tealium";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { environment } from "../../config/environment";
import IMAGES from "../../assets/img";
import { AnalyticsEvent } from "../../classes/createEvent";
import { useWebSocket } from "../../providers/websocketProvider.jsx";

function Identity(props: any) {
  const today = new Date();
  const divRef: any = React.createRef<HTMLDivElement>();
  const navigate: any = useNavigate();
  const store = useSelector((state: any) => state);
  const { addClient, config, viewWelcomeMdl, spanDomains, sourceIp, ipCity } =
    props;

  useTealium({
    type: "view",
    structure: {
      pagePath: window.location.pathname,
    },
  });

  const { createNewConnection, socketId } = useWebSocket();

  const { executeRecaptcha }: any = useGoogleReCaptcha();
  const [dataStore] = useState(store);

  const [values, setValues]: any = React.useState({
    expedition: new Field(),
    names: new Field(),
    surnames: new Field(),
    email: new Field(),
    emailConfirm: new Field(),
    phone: new Field(),
    id: new Field(),
  });

  const [fail, setFail] = useState(false);
  const [failEmail, setFailEmail] = useState(false);
  const [failEmailstate, setFailEmailState] = useState(false);
  const [identityGlobal, setIdentityGlobal] = React.useState({
    canContinue: false,
    showLoading: false,
  });
  const spamEmails = useRef();

  const [showErrors, setShowErrors]: any = React.useState({
    codes: [],
    show: false,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    spamEmails.current = spanDomains;
  }, [spanDomains]);

  const validEvent = (e: any) => {
    if (eventValid(e, values, setValues)) {
      validDate(e, () => {
        handleGlobal(validObject(values), "canContinue");
      });
    }
    if (e.target.name === "email") {
      validSpamEmail(e, () => {
        handleGlobal(validObject(values), "canContinue");
      });
      validateEqualityMail(e, () => {
        handleGlobal(validObject(values), "canContinue");
      });
    }
    if (e.target.name === "emailConfirm") {
      validateEqualityMail(e, () => {
        handleGlobal(validObject(values), "canContinue");
      });
    }
  };

  const validDate = (e: any, callback: any) => {
    const { target } = e;
    const newData = cleanDate(target ? target?.value : "");
    validEventDate(
      e,
      isNaN(Date.parse(newData)) ||
        new Date(newData).getTime() > today.getTime(),
      `#identity_${target?.name}`,
      "La fecha de expedición de la cédula no puede ser mayor a la fecha actual",
      setFail,
      callback,
    );
  };

  const validateEqualityMail = (e: any, callback: any) => {
    const { target } = e;
    const { value, name } = target;
    let equalEmails = true;
    if (
      name === "emailConfirm" &&
      values.email.value !== "" &&
      values.email.value !== null
    ) {
      equalEmails = values.email.value === value;
    } else if (
      name === "email" &&
      values.emailConfirm.value !== "" &&
      values.emailConfirm.value !== null
    ) {
      equalEmails = values.emailConfirm.value === value;
    }

    if (e.detail.valid === true) {
      validatMailEquality(
        e,
        equalEmails ? false : true,
        `#identity_${name}`,
        "El correo electrónico no coincide.",
        setFailEmail,
        callback,
      );
    }
  };

  const validSpamEmail = (e: any, callback: any) => {
    const { target } = e;
    const { value, name } = target;
    if (value) {
      const domain: string = value?.split("@")[1];

      const emailList: string[] = spamEmails.current || [];
      const existDomain = emailList.includes(domain);

      validateSpamEmail(
        e,
        existDomain,
        `#identity_${name}`,
        "No es posible registrar este correo electrónico. Por favor, ingresa otro.",
        setFailEmail,
        callback,
      );
    }
  };

  const emailExistDB = (e: any, callback: any) => {
    ValidateEmailDB(
      e,
      true,
      `#identity_email`,
      "Este correo ya está en uso.",
      setFailEmailState,
      callback,
    );
  };

  useEffect(() => {
    createNewConnection();
    const inputs = document.querySelectorAll("focc-field");
    inputs.forEach((input: any) => {
      input?.addEventListener("valid", validEvent);
    });

    return () => {
      values;
    };
  }, []);

  useEffect(() => {
    setIsModalVisible(viewWelcomeMdl);
  }, [viewWelcomeMdl]);

  const getValidEmail = async () => {
    let isValid = true;
    const sendData = {
      email: values.email.value,
      idNumber: convertNumberToStringWithoutSymbols(values.id.value),
    };

    const resp = await clientService.existEmails(sendData);
    const data = resp.data.details.find((item) => item.key === "data");
    isValid = data.value;

    return isValid;
  };

  const findEmailExist = async () => {
    if (!(await getValidEmail())) {
      emailExistDB(null, () => {
        handleGlobal(true, "showLoading");
      });
      return;
    }

    setShowErrors({
      ...showErrors,
      show: false,
    });

    const data = {
      clientIdType: ConstantPid.DOCUMENT_TYPE,
      id: convertNumber(values.id.value),
      names: values.names.value,
      surnames: values.surnames.value,
      phone: convertNumber(values.phone.value),
      email: values.email.value,
      expedition: values.expedition.value,
    };

    if (config?.status) {
      failFn(data);
    } else {
      const person = {
        uuid: socketId,
        sendAnality: {
          send: true,
          userAgent: navigator.userAgent,
          event: ["InfoClient"],
        },
        infoClient: { ...data },
      };
      addClient(person);
      handleGlobal(true, "showLoading");
      if (environment.enableCaptcha) {
        verifyRecaptcha(person);
      } else {
        savePersonData(person);
      }
    }
  };
  const savePersonData = (person: any) => {
    const details = [
      {
        key: "Names",
        value: person.infoClient.names,
      },
      {
        key: "Email",
        value: person.infoClient.email,
      },
      {
        key: "Id",
        value: `${person.infoClient.id}`,
      },
      {
        key: "Phone",
        value: `${person.infoClient.phone}`,
      },
      {
        key: "Surnames",
        value: person.infoClient.surnames,
      },
      {
        key: "ClientIdType",
        value: person.infoClient.clientIdType,
      },
      {
        key: "Ubicación de la IP",
        value: ipCity,
      },
      {
        key: "uuid",
        value: person.uuid,
      },
    ];

    const analyticsEvent = getAnalyticsEvent(EventCodeEnum.CODE_40, details);
    analyticsEvent.sendEvent().catch((err) => {
      failFn(details);
    });

    navigate("/pep");
    handleGlobal(false, "showLoading");
  };

  const failFn = (details: any) => {
    clear();

    const analyticsEvent = getAnalyticsEvent(
      EventCodeEnum.CODE_59,
      details.push({ key: "Path", value: window.location.pathname }),
    );
    analyticsEvent.sendEvent().catch((err) => {
      console.log("err", err);
    });

    setShowErrors({
      codes: [StatusCodeEnum.CODE_500],
      show: true,
    });
    handleGlobal(false, "canContinue");
  };

  const getAnalyticsEvent = (code: EventCodeEnum, details: any[]) => {
    const analyticsEvent = new AnalyticsEvent(code, dataStore, details);
    analyticsEvent.client = {
      id: values.id.value,
      clientIdType: ConstantPid.DOCUMENT_TYPE,
      uuid: socketId,
    };
    analyticsEvent.sourceIp = sourceIp;
    return analyticsEvent;
  };

  const errorCaptcha = () => {
    setShowErrors({
      codes: [StatusCodeEnum.CODE_313],
      show: true,
    });
    handleGlobal(false, "showLoading");
  };

  const handleGlobal = (value: any, name: string) => {
    updState(value, name, identityGlobal, setIdentityGlobal);
  };

  const handleChange = (evt: any) => {
    const { target } = evt;
    const { name, value } = target;
    const old = values[name];

    if (name === "expedition") {
      setFail(false);
    }

    if (name == "email" || name == "emailConfirm") {
      old.value = value;
    } else {
      old.value = value;
    }
    updState(old, name, values, setValues);
  };

  const verifyRecaptcha = (person: any) => {
    executeRecaptcha("identity").then((token: any) => {
      captchaService
        .validateCaptcha({ token })
        .then((resp: any) => {
          const { data } = resp;
          if (data && data.code === 200) {
            savePersonData(person);
          } else {
            errorCaptcha();
          }
        })
        .catch(() => errorCaptcha());
    });
  };

  const clear = () => {
    values.expedition.clear();
    values.names.clear();
    values.surnames.clear();
    values.email.clear();
    values.emailConfirm.clear();
    values.phone.clear();
    values.id.clear();
  };

  const openUrl = (option: number) => {
    if (option === 1) {
      window.open(environment.simulador_url, "_blank", "noreferrer");
    } else if (option === 2) {
      window.open(environment.fiduoccidente_url, "_parent", "noreferrer");
    } else {
      setIsModalVisible(false);
    }
  };

  return (
    <div ref={divRef}>
      {
        <HeaderContent
          title={"Queremos conocerte"}
          subtitle={"Ingresa tus datos"}
        />
      }

      <div className="form-container">
        <focc-field
          id="identity_names"
          type="text"
          value={values.names.value}
          format="on"
          max={20}
          name="names"
          placeholder="Ej. Maria Alejandra (sin tildes)"
          onInput={handleChange}
          onValid={validEvent}
          required
        >
          Tus nombres*
        </focc-field>
        <focc-field
          id="identity_surnames"
          type="text"
          format="on"
          max={20}
          value={values.surnames.value}
          name="surnames"
          placeholder="Ej. Diaz Morales (sin tildes)"
          onValid={validEvent}
          onInput={handleChange}
          required
        >
          Tus apellidos*
        </focc-field>
        <focc-field
          id="identity_id"
          type="number"
          format="on"
          max={10}
          value={values.id.value}
          name="id"
          placeholder="Ej. 122233344"
          onValid={validEvent}
          onInput={handleChange}
          required
        >
          Tu número de cédula*
        </focc-field>
        <focc-field
          id="identity_expedition"
          type="date"
          fail={fail}
          value={values.expedition.value}
          name="expedition"
          placeholder="01/08/2000"
          onValid={validEvent}
          onInput={handleChange}
          required
        >
          Fecha de expedición de tu cédula*
        </focc-field>

        <focc-field
          id="identity_email"
          type="email"
          value={values.email.value}
          fail={failEmail}
          name="email"
          placeholder="Ej. nombre@email.com"
          onValid={validEvent}
          onInput={handleChange}
          onChange={handleChange}
          upper={true}
          required
        >
          Tu correo electrónico*
        </focc-field>

        <focc-field
          id="identity_emailConfirm"
          type="email"
          value={values.emailConfirm.value}
          fail={failEmail}
          name="emailConfirm"
          placeholder="Ej. nombre@email.com"
          onInput={handleChange}
          onChange={handleChange}
          onValid={validEvent}
          upper={true}
          nopaste={true}
          required
        >
          Confirmar correo electrónico*
        </focc-field>

        <focc-field
          id="identity_phone"
          type="cel"
          min={10}
          max={10}
          value={values.phone.value}
          name="phone"
          placeholder="Ej. 3203338877"
          onValid={validEvent}
          onInput={handleChange}
          required
        >
          Tu número de celular*
        </focc-field>
      </div>

      {failEmailstate === true && (
        <Alert
          type="error"
          text="Este correo ya fue registrado, intenta con otro."
        ></Alert>
      )}

      <div className="center-button">
        <focc-button
          id="identity_btnLoading"
          onClick={findEmailExist}
          loading={identityGlobal.showLoading ? "on" : "off"}
          disabled={!identityGlobal.canContinue}
        >
          Continuar
        </focc-button>
        <ErrorModal
          reload
          errorCode={showErrors.codes[0]}
          isVisible={showErrors.show}
        ></ErrorModal>
      </div>

      <Modal
        title={null}
        open={isModalVisible}
        onCancel={() => openUrl(3)}
        bodyStyle={{ overflowY: "scroll" }}
        footer={null}
      >
        <div className="content-modal-welcome content-modal">
          <div className="container-img-header">
            <img
              alt=""
              src={`${environment.cdn}/welcome_image.png`}
              className="img-header"
            />
            <div className="text-header">
              {" "}
              ¡Bienvenido a<br />
              FiduOccidente!
            </div>
          </div>

          <div className="container-body">
            <div className="sub-title">
              <div>Abre tu fondo de inversión y cumplamos tus metas juntos</div>
              <div className="name-product">
                <img
                  alt=""
                  src={`${environment.cdn}/FondosLarge/100-4.svg`}
                  className="img-product"
                />
                <span className="txt-product">Conservador</span>
              </div>
            </div>
            <div className="item">
              <div className="item-img">
                <img alt="" src={IMAGES["timerIcon"]} width="19" />
              </div>
              Haz la apertura desde cualquier dispositivo en tan solo 5 min
            </div>
            <div className="item">
              <div className="item-img">
                <img alt="" src={IMAGES["moneyIcon"]} width="19" />
              </div>
              Invierte desde $200.000
            </div>
            <div className="item">
              <div className="item-img">
                <img alt="" src={IMAGES["downloadIcon"]} width="19" />
              </div>
              Sin pacto de permanecia
            </div>
          </div>

          <div className="container-footer">
            <div className="btn-modal">
              <focc-button
                id="btnFondoWel"
                class="button-wel"
                onClick={() => openUrl(3)}
              >
                Abre tu fondo
              </focc-button>
            </div>

            <div className="btn-modal">
              <button
                id="btnSimulaWel"
                className="btn-secondary"
                onClick={() => openUrl(2)}
              >
                Abandonar el proceso
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    client: state.vinculation.client,
    config: state.configuration.fail,
    spanDomains: state.configuration.parameters.domainSpamEmails,
    viewWelcomeMdl: state.configuration.parameters.focc_is_show_welcome_modal,
    sourceIp: state.configuration.parameters.source_ip,
    ipCity: state.configuration.parameters.ip_city,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addClient: (data: any) => dispatch(addClient(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Identity);
