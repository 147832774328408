import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Home } from "../../layouts";
import { Identity, Product, NotFound } from "../../pages";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PepTerms from "../pep-terms/pepterms";
import Validation from "../validation/validation";
import Origin from "../origin/origin";
import Residence from "../residence/residence";
import Finance from "../finance/finance";
import { parameterService } from "../../services";
import { maintenanceService } from "../../services";
import { setConfiguration, setConfigurationFail } from "../../store/actions";
import Confirmation from "../confirmation/confirmation";
import ProductDetails from "../product-details/productdetails";
import { environment } from "../../config/environment";
import WithDrawal from "../withdrawal/withdrawal";
import Maintenance from "../maintenance/maintenance";

function App(props: any) {
  const { setConfig, setFail } = props;
  const [isMantenimiento, setMantenimiento] = useState(false);

  useEffect(() => {
    const result = async () => {
      return await maintenanceService.getMaintenance();
    };

    result()
      .then((response) => {
        const isEnableMaintenance = response.data.find(
          (x: any) => x.flowName === "AUTO",
        ).flowStatus;
        if (isEnableMaintenance === "N") {
          setMantenimiento(true);
        }
      })
      .catch(() => {
        console.log("error maintenance");
        setFail({ status: true });
      });
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      return await parameterService.getParameters();
    };

    fetchData()
      .then((response) => {
        const listParameter = response.data.parameters;

        const param: any = {
          ciudad: listParameter.filter(
            (x: any) => x.keyParameter === "CIUDAD",
          )[0].items,
          nacionalidad: listParameter.filter(
            (x: any) => x.keyParameter === "NACIONALIDAD",
          )[0].items,
          profesion: listParameter.filter(
            (x: any) => x.keyParameter === "PROFESION",
          )[0].items,
          actividadEconomica: listParameter.filter(
            (x: any) => x.keyParameter === "CIIU",
          )[0].items,
          desistimiento: listParameter.filter(
            (x: any) => x.keyParameter === "DESISTIMIENTO_DEL_PROCESO",
          )[0].items,
          domainSpamEmails: listParameter
            .filter((x: any) => x.keyParameter === "SPAM_EMAIL")[0]
            .items.map((x: any) => x.value),
          source_ip: response.data.request_ip,
          ip_city: response.data.ip_city,
        };

        const resultParam = setDynamicParameters(response.data.parameters_ssm);

        setFail({ status: false });
        setConfig({ ...param, ...resultParam });
      })
      .catch(() => {
        setFail({ status: true });
      });
    return () => {
      isSubscribed = false;
    };
  }, [setConfig, setFail]);

  const setDynamicParameters = (parameters: any) => {
    const param: any = {};
    parameters.forEach((element: any) => {
      const nameParam = element.description.replaceAll("-", "_");
      param[nameParam] = JSON.parse(element.value);
    });
    return param;
  };

  return (
    <div>
      <BrowserRouter basename={environment.generalPath}>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route
              path="/"
              element={
                isMantenimiento ? (
                  <Navigate replace to={"/mantenimiento"} />
                ) : (
                  <Identity />
                )
              }
            />
            <Route path="/pep" element={<PepTerms />} />
            <Route path="/producto" element={<Product />} />
            <Route path="/detalleProducto" element={<ProductDetails />} />
            <Route path="/validacion" element={<Validation />} />
            <Route path="/origen" element={<Origin />} />
            <Route path="/residencia" element={<Residence />} />
            <Route path="/finanzas" element={<Finance />} />
            <Route path="/confirmacion" element={<Confirmation />} />
            <Route path="/desistimiento" element={<WithDrawal />} />
            <Route path="notfound" element={<NotFound />} />
          </Route>
          <Route path="/mantenimiento" element={<Maintenance />} />
          <Route path="/home" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    client: state.vinculation.client,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setConfig: (data: any) => dispatch(setConfiguration(data)),
    setFail: (data: any) => dispatch(setConfigurationFail(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
