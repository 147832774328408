import { ConstantPid, WebsocketActionEnum } from "../enums/constantPid";

const setClientOtp = (
  client: any,
  uuid: string,
  type: "SMS" | "EMAIL",
  isResend: boolean,
) => {
  const clientInfo = client;
  const names = clientInfo.names.split(" ");
  const surnames = clientInfo.surnames.split(" ");
  const secondName = clientInfo.names?.substring(names[0].length);
  const secondLastName = clientInfo.surnames?.substring(surnames[0].length);

  const data: any = {
    client: {
      first_name: names[0],
      middle_name: secondName.trim() || "",
      primary_last_name: surnames[0],
      second_last_name: secondLastName.trim() || "",
      type_id: "CC",
      id: `${clientInfo.id}`,
      cell_phone_number: `${clientInfo.phone}`,
      send_type: type,
      resend: isResend ? "true" : "false",
      captcha_token: "",
      is_presales: false,
      source_ip_address: "",
      email: `${clientInfo.email}`,
    },
    controlData: {
      UUIDSession: uuid,
      isAuto: true,
      action: WebsocketActionEnum.VALIDAR_CLIENTE,
    },
    connection_params: {
      UUIDSession: uuid,
      flow: ConstantPid.FLOW,
    },
    analytics: {
      userAgent: navigator.userAgent,
      clientIdType: "CC",
      clientId: `${clientInfo.id}`,
      channel: "PID App Auto",
      sessionId: uuid,
      advisorId: ConstantPid.ADVISORID,
      transactionId:
        clientInfo.id + "-" + ConstantPid.ADVISORID + "-" + Date.now(),
    },
    controlSQS: {
      sendMessage: true,
      sqsReferenceName: "sqs-auto-validar-identidad",
    },
    TrnSrcInfo: {
      EnhancedParams: {
        primary_last_name: `${surnames[0]}`,
        type_id: `${clientInfo.clientIdType}`,
        id: `${clientInfo.id}`,
        cell_phone_number: `${clientInfo.phone}`,
      },
    },
  };
  return data;
};

export { setClientOtp };
