export enum ConstantPid {
  DOCUMENT_TYPE = "CC",
  S = "S",
  N = "N",
  ADVISORID = "26894",
  FLOW = "auto",
  CHANNEL = "PID-APP AUTO",
  APPLICATION = "focc-pid-vinculacion-autogestion",
  EMAIL_TEMPLATE = "templates/template-email-client.html",
}

export enum WebsocketActionEnum {
  VALIDAR_CLIENTE = "validar-cliente",
  VALIDAR_INFO_FINANCIERA_AUTO = "informacion-financiera",
  CREAR_INVERSION = "crear-inversion",
}

export enum StatusCodeEnum {
  CODE_200 = 200,
  CODE_214 = 214,
  CODE_313 = 313,
  CODE_314 = 314,
  CODE_317 = 317,
  CODE_329 = 329,
  CODE_500 = 500,
  CODE_599 = 599,
}

export enum PathNames {
  OTP = "/validacion-otp",
  FINANCE = "/validacion-info-financiera",
}

/**
 * Code of eventMnemonic
 */
export enum EventCodeEnum {
  CODE_40 = 40,
  CODE_42 = 42,
  CODE_46 = 46,
  CODE_47 = 47,
  CODE_48 = 48,
  CODE_54 = 54,
  CODE_59 = 59,
  CODE_60 = 60,
  CODE_61 = 61,
}
