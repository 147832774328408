import { useState } from "react";
import { Steps } from "antd";
import Otp from "../../components/otp/ValidOtp";
import FinancialData from "../../components/financial-data/FinancialData";
import { connect } from "react-redux";
import { resetStore, updateClient } from "../../store/actions";

const { Step } = Steps;

function Validation(props: any) {
  const stepsGlobal: any = [
    {
      id: 1,
      title: "Identidad",
      status: "wait",
    },
    {
      id: 2,
      title: "Datos Financieros",
      status: "wait",
    },
  ];
  const {
    client,
    updClient,
    resetRedux,
    timeFinishOtp,
    uuid,
    tab,
    descOtp,
    sourceIp,
  } = props;
  const [current, setCurrent] = useState(tab || 0);
  const [itemsValid, setItemsValid] = useState({ list: stepsGlobal });
  const [valueOtp, setValueOtp] = useState({});

  const validationIdentity = () => {
    setCurrent(current + 1);
    const identityStep = stepsGlobal[0];
    identityStep.status = "finish";
    setItemsValid({
      ...itemsValid,
      list: stepsGlobal,
    });
  };

  const validationFinance = (e: any) => {
    const identityStep = stepsGlobal[1];

    if (e) {
      const infoFinance = {
        ...client,
        infoJob: e,
        otpValue: valueOtp,
      };
      updClient(infoFinance);
      identityStep.status = "finish";
      setItemsValid({
        ...itemsValid,
        list: stepsGlobal,
      });
    } else {
      identityStep.status = "error";
      setItemsValid({
        ...itemsValid,
        list: stepsGlobal,
      });
    }
  };

  const finishProcess = () => {
    resetRedux();
  };

  return (
    <div className="flex-simple-center p-29">
      <Steps current={current}>
        {itemsValid.list.map((item: any) => (
          <Step status={item.status} key={item.id} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">
        {itemsValid.list[current].id === 1 ? (
          <Otp
            setOtp={validationIdentity}
            timeOtp={timeFinishOtp}
            descOtp={descOtp}
            phone={client.infoClient.phone}
            client={client.infoClient}
            uuid={uuid}
            updClient={updClient}
            setValueOtp={setValueOtp}
            sourceIp={sourceIp}
          />
        ) : (
          <FinancialData
            client={client.infoClient}
            uuid={uuid}
            setFinance={validationFinance}
            fnReset={finishProcess}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    client: state.vinculation.client,
    uuid: state.vinculation.client.uuid,
    timeFinishOtp: state.vinculation.validations.timeOtp,
    descOtp: state.vinculation.validations.descOtp,
    sourceIp: state.configuration.parameters.source_ip,
  };
};

import React from "react";

const mapDispatchToProps = (dispatch: any) => {
  return {
    updClient: (data: any) => dispatch(updateClient(data)),
    resetRedux: () => dispatch(resetStore()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Validation);
