import { Subject } from "rxjs";

export class WebSocketService {
  private urlWs: string;
  private protocols: string[];
  public newSocket: any;
  private subject = new Subject<boolean>();

  constructor(urlWs: string, protocols?: string[]) {
    this.urlWs = urlWs;
    this.protocols = protocols || [];
    this.connect();
  }

  public connect() {
    this.newSocket = new WebSocket(`${this.urlWs}`, this.protocols);

    this.newSocket.onopen = () => {
      setInterval(() => {
        this.newSocket.send(JSON.stringify({ action: "ping" }));
      }, 60000);
    };

    this.newSocket.onmessage = (event: any) => {
      const response = JSON.parse(event.data);
      if (response?.pid_response !== "pong") {
        this.subject.next(response);
      }
    };

    this.newSocket.onclose = (event: CloseEvent) => {
      const code = event.code;
      if (code == 1005) {
        console.log(`*** WebSocket closed with code ${code} ***`);
      } else {
        console.log(
          `*** WebSocket closed with code ${code}. Reconnecting... ***`,
        );
        setTimeout(() => {
          this.connect();
        }, 5000);
      }
    };
  }

  public getMessages$() {
    return this.subject.asObservable();
  }

  public close() {
    this.newSocket.close();
  }
}
