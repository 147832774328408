import {
  CLIENT_ADD,
  CLIENT_UPDATE,
  CONFIGURATION_ADD,
  CONFIGURATION_FAIL_ADD,
  PRODUCT_ADD,
  PRODUCT_UPDATE,
  RESET_STORE,
  VALIDATION_ADD,
} from "../types";

export const addClient = (data: any) => {
  return {
    type: CLIENT_ADD,
    payload: data,
  };
};

export const updateClient = (data: any) => {
  return {
    type: CLIENT_UPDATE,
    payload: data,
  };
};

export const addProduct = (data: any) => {
  return {
    type: PRODUCT_ADD,
    payload: data,
  };
};

export const updateProduct = (data: any) => {
  return {
    type: PRODUCT_UPDATE,
    payload: data,
  };
};

export const addValidation = (data: any) => {
  return {
    type: VALIDATION_ADD,
    payload: data,
  };
};

export const setConfiguration = (data: any) => {
  return {
    type: CONFIGURATION_ADD,
    payload: data,
  };
};

export const setConfigurationFail = (data: any) => {
  return {
    type: CONFIGURATION_FAIL_ADD,
    payload: data,
  };
};

export const resetStore = () => {
  return {
    type: RESET_STORE,
  };
};
