import { Checkbox } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import politicasHtml from "../../assets/pid/html/politicas.html";
import {
  ConstantPid,
  EventCodeEnum,
  StatusCodeEnum,
  WebsocketActionEnum,
} from "../../enums/constantPid";
import { ErrorModal, ButtonWithdrawal } from "../";
import { clientService } from "../../services";
import { setClientOtp } from "../../utils";
import { IClient } from "../../interfaces/client.interface";
import { AnalyticsEvent } from "../../classes/createEvent";
import { useSelector } from "react-redux";
import { useWebSocket } from "../../providers/websocketProvider.jsx";

function confirmProduct(props: any) {
  const { socketId, socket } = useWebSocket();

  const navigate: any = useNavigate();
  const store = useSelector((state: any) => state);

  const { product, updProduct, updClient, addValidation, client } = props;

  const [canContinue, setCanContinue] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [showErrors, setShowErrors]: any = useState({
    codes: [],
    show: false,
  });

  const [dataStore] = useState(store);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const validateForm = () => {
    const politicaProdChk: any = document.getElementById("politicaProdChk");
    if (politicaProdChk !== null && politicaProdChk.checked && product) {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  };

  useEffect(() => {
    socket.getMessages$().subscribe((response: any) => {
      if (
        response.pid_response.action === WebsocketActionEnum.VALIDAR_CLIENTE
      ) {
        if (response.pid_response.code == 201) {
          addValidation({
            timeOtp: response.pid_response?.data?.time_otp || 10,
            descOtp: response.pid_response?.data?.desc_otp,
          });
          navigate("/validacion");
          setShowLoading(false);
        } else {
          setShowErrors({
            codes: [response.pid_response.code],
            show: true,
          });
          setShowLoading(false);
        }
      }
    });
    return () => {
      socket;
    };
  }, []);

  const nextContinue = () => {
    setShowLoading(true);

    const newClient: IClient = {
      ...client,
      sendAnality: {
        ...client.sendAnality,
        event: ["Product"],
      },
      product: {
        codigosifi: product.codigosifi,
        producto: product.producto,
        templateCorreo: product.templateCorreo,
      },
    };

    updProduct(product);
    updClient(newClient);

    const details = [
      {
        key: "Producto",
        value: product.producto,
      },
      {
        key: "Codigosifi",
        value: product.codigosifi,
      },
      {
        key: "TemplateCorreo",
        value: ConstantPid.EMAIL_TEMPLATE,
      },
      {
        key: "uuid",
        value: client.uuid,
      },
    ];

    const analyticsEvent = getAnalyticsEvent(EventCodeEnum.CODE_42, details);
    analyticsEvent
      .sendEvent()
      .then()
      .catch((err) => {
        setShowLoading(false);

        const details = [
          {
            key: "uuid",
            value: client.uuid,
          },
          {
            key: "Code",
            value: StatusCodeEnum.CODE_500,
          },
          {
            key: "Id",
            value: client.infoClient.id,
          },
          {
            key: "FullName",
            value: `${client.infoClient.names} ${client.infoClient.surnames}`,
          },
          {
            key: "Phone",
            value: client.infoClient.phone,
          },
          {
            key: "Email",
            value: client.infoClient.email,
          },
          {
            key: "Path",
            value: window.location.pathname,
          },
        ];

        const analyticsEventFail = getAnalyticsEvent(
          EventCodeEnum.CODE_59,
          details,
        );
        analyticsEventFail
          .sendEvent()
          .then()
          .catch((err) => {
            console.log("err", err);
          });

        setShowErrors({
          codes: [StatusCodeEnum.CODE_500],
          show: true,
        });
      });

    validateClientOTP();
  };

  const getAnalyticsEvent = (code: EventCodeEnum, details: any[]) => {
    return new AnalyticsEvent(code, dataStore, details);
  };

  const validateClientOTP = () => {
    const data: any = setClientOtp(client.infoClient, socketId, "SMS", false);

    clientService.validateClient(data).catch((e: Error) => {
      console.error("error post :>> ", e);
      setShowLoading(false);
    });
  };

  return (
    <div>
      <div
        className={
          product?.codigosifi
            ? "reglamento show-animation-container"
            : "reglamento"
        }
      >
        <Checkbox
          onChange={validateForm}
          name="politicaProdChk"
          id="politicaProdChk"
          className="politicaProdChk"
        ></Checkbox>

        <span className="small-text-14">
          Aceptas el{" "}
          <a
            className="text-link"
            id="link-reglamentoProducto"
            target="_blank"
            rel="noopener noreferrer"
            href={product.urlReglamento}
          >
            Reglamento
          </a>
          ,
          <a
            className="text-link"
            id="link-prospectoProducto"
            target="_blank"
            rel="noopener noreferrer"
            href={product.urlProspecto}
          >
            {" "}
            Prospecto
          </a>{" "}
          y
          <span id="politicaSpan" className="text-link" onClick={showModal}>
            {" "}
            Políticas del producto{" "}
          </span>
        </span>
      </div>

      <div className="container-flex">
        <focc-button
          id="btnLoading"
          disabled={!canContinue}
          onClick={nextContinue}
          loading={showLoading ? "on" : "off"}
        >
          Quiero invertir
        </focc-button>
        <ButtonWithdrawal client={client} />
      </div>

      <Modal
        title="Políticas del producto"
        open={isModalVisible}
        style={{ height: "calc(100vh - 200px)" }}
        bodyStyle={{ overflowY: "scroll" }}
        onCancel={closeModal}
        footer={[
          <focc-button
            key="btnLeidoAcepto"
            id="btnLeidoAcepto"
            onClick={closeModal}
          >
            He leído y acepto
          </focc-button>,
        ]}
      >
        <div
          className="content-modal"
          dangerouslySetInnerHTML={{ __html: politicasHtml }}
        />
      </Modal>
      <ErrorModal
        errorCode={showErrors.codes[0]}
        isVisible={showErrors.show}
      ></ErrorModal>
    </div>
  );
}

export default confirmProduct;
