const IMAGES: any = {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  "valid-loading": require("./valid-loading.svg"),
  "valid-success": require("./valid-success.svg"),
  "valid-error": require("./valid-error.svg"),
  "credito-aprobado": require("./credito-aprobado.svg"),
  "fidu-inversion": require("./fiduinversion.svg"),
  oficina: require("./oficina.svg"),
  "portal-banco": require("./portal-banco.svg"),
  pse: require("./pse.svg"),
  "validar-identidad": require("./identidad.svg"),
  llamada: require("./llamada.svg"),
  warning: require("./warning.svg"),
  error: require("./error.svg"),
  arrow: require("./arrow.svg"),
  administracion: require("./administracion.svg"),
  pactoPertenencia: require("./pactoPertenencia.svg"),
  retiroAnticipado: require("./retiroAnticipado.svg"),
  "arrow-right": require("./arrow-right.svg"),
  "arrow-down": require("./arrow-down.svg"),
  "arrow-up": require("./arrow-up.svg"),
  "arrow-down2": require("./arrow-02.svg"),
  infoPep: require("./info-pep.svg"),
  desistimiento: require("./desistimiento.svg"),
  timerIcon: require("./timer_icon.svg"),
  moneyIcon: require("./money_icon.svg"),
  downloadIcon: require("./download_icon.svg"),
  inactividad: require("./inactividad.svg"),
  maintenance: require("./mantenimiento.svg"),
  errorAlert: require("./errorAlert.svg"),
  phone: require("./phone.svg"),
};

export default IMAGES;
