import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IMAGES from "../../assets/img";
import {
  ConstantPid,
  PathNames,
  WebsocketActionEnum,
} from "../../enums/constantPid";
import { financeService } from "../../services";
import useTealium from "../tealium/tealium";
import { useWebSocket } from "../../providers/websocketProvider.jsx";

function FinancialData(props: any) {
  const navigate: any = useNavigate();
  const { socket } = useWebSocket();

  const { setFinance, client, uuid, fnReset } = props;
  const [tealium] = useTealium({
    type: "view",
    structure: {
      pagePath: PathNames.FINANCE,
    },
  });

  const [globalFinance, setGlobalFinance] = React.useState({
    showLoading: true,
    success: false,
    fail: false,
  });

  const handleGlobal = (fail?: boolean) => {
    setGlobalFinance({
      showLoading: false,
      success: !fail,
      fail: fail || globalFinance.fail,
    });
  };

  const callService = () => {
    const data = {
      client: {
        type_id: client.clientIdType,
        id: client.id.toString(),
        source_ip_address: "3.13.132.40",
        is_presales: false,
      },
      connection_params: {
        UUIDSession: uuid,
        flow: ConstantPid.FLOW,
      },
      analytics: {
        userAgent: "",
        clientIdType: client.clientIdType,
        clientId: client.id.toString(),
        channel: "PID App Auto",
        sessionId: uuid,
        advisorId: ConstantPid.ADVISORID,
        transactionId:
          client.id + "-" + ConstantPid.ADVISORID + "-" + Date.now(),
      },
      controlSQS: {
        sendMessage: true,
        sqsReferenceName: "sqs-auto-validar-financiera",
      },
      controlData: {
        UUIDSession: uuid,
        isAuto: true,
        action: WebsocketActionEnum.VALIDAR_INFO_FINANCIERA_AUTO,
      },
    };
    financeService.validateInfoFinance(data).catch(() => {
      handleGlobal(true);
    });
  };

  useEffect(() => {
    callService();
    socket.getMessages$().subscribe((response: any) => {
      if (
        response.pid_response.action ===
        WebsocketActionEnum.VALIDAR_INFO_FINANCIERA_AUTO
      ) {
        if (response.pid_response.code == 201) {
          handleGlobal(false);
          setFinance(response.pid_response.data);
        } else {
          handleGlobal(true);
          setFinance(null);
        }
      }
    });
    return () => {
      socket;
    };
  }, []);

  const returnFinance = () => {
    if (globalFinance.fail) {
      fnReset?.call();
      navigate("/");
    } else {
      navigate("/origen");
    }
  };

  return (
    <div className="flex-simple-center gap-40">
      <img
        className="imagen m-auto fondo"
        src={
          globalFinance.fail
            ? IMAGES["valid-error"]
            : globalFinance.success
            ? IMAGES["valid-success"]
            : IMAGES["valid-loading"]
        }
        width="160"
        alt="..."
      />
      {globalFinance.showLoading ? (
        <span className="content mt-10">
          {" "}
          Los <strong>datos financieros</strong> están siendo validados{" "}
        </span>
      ) : globalFinance.success && !globalFinance.fail ? (
        <span className="content mt-10">
          {" "}
          La validación de <strong>identidad</strong> y los{" "}
          <strong>datos financieros</strong> ha sido exitosa{" "}
        </span>
      ) : (
        <span className="content mt-10">
          {" "}
          La validación de <strong>identidad</strong> y los{" "}
          <strong>datos financieros</strong> no ha sido exitosa{" "}
        </span>
      )}
      <div className="center-button">
        <focc-button
          class={globalFinance.showLoading ? "" : "hydrated button-primary"}
          id="btnLoadingFinancial"
          onClick={returnFinance}
          loading={globalFinance.showLoading ? "on" : "off"}
        >
          {globalFinance.showLoading ? "Validando" : "Continuar"}
        </focc-button>
      </div>
    </div>
  );
}
export default FinancialData;
